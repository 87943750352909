import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { Model } from '../../infra/model'
import { DTypes, locator } from '../../infra/registry'
import { activateSubscriptionMutation } from '../../users/graphql/mutations'
import { Company } from '../entities/company'
import {
  DayAggregate,
  MonthAggregate,
  StatusAggregate,
} from '../entities/stats'
import {
  updateCompanyMutation,
  updateRemovalUsageMutation,
} from '../graphql/mutations'
import {
  canceledByMonthQuery,
  searchCompanies,
  subscribersByDayQuery,
  subscribersByMonthQuery,
  subscribersByStatusQuery,
  trialsByDayQuery,
  trialsByMonthQuery,
} from './../graphql/queries'

@injectable()
export class CompanyProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  // async changeIdSubscription(
  //   id: string,
  //   subscriptionId: string,
  // ): Promise<boolean> {
  //   return this.apiService
  //     .graphql<{ changeIdSubscription: boolean }>(
  //       changeIdSubscriptionMutation,
  //       {
  //         id,
  //         subscriptionId,
  //       },
  //     )
  //     .then(r => r.changeIdSubscription)
  // }

  async activateSubscription(id: string): Promise<boolean> {
    return this.apiService
      .graphql<{ activateSubscription: boolean }>(
        activateSubscriptionMutation,
        {
          id,
        },
      )
      .then(r => r.activateSubscription)
  }

  async update(company: Partial<Company>): Promise<Model> {
    return this.apiService
      .graphql<{ updateCompany: Model }>(updateCompanyMutation, {
        data: company,
      })
      .then(r => r.updateCompany)
  }

  async searchCompanies(email: string): Promise<Company[]> {
    return this.apiService
      .graphql<{ searchCompanies: Company[] }>(searchCompanies, {
        email,
      })
      .then(r => r.searchCompanies)
  }

  async subscribersByStatus(): Promise<StatusAggregate[]> {
    return this.apiService
      .graphql<{ subscribersByStatus: StatusAggregate[] }>(
        subscribersByStatusQuery,
      )
      .then(r => r.subscribersByStatus)
  }

  async trialsByMonth(): Promise<MonthAggregate[]> {
    return this.apiService
      .graphql<{ trialsByMonth: MonthAggregate[] }>(trialsByMonthQuery)
      .then(r => r.trialsByMonth)
  }

  async subscribersByDay(): Promise<DayAggregate[]> {
    return this.apiService
      .graphql<{ subscribersByDay: DayAggregate[] }>(subscribersByDayQuery)
      .then(r => r.subscribersByDay)
  }

  async trialsByDay(): Promise<DayAggregate[]> {
    return this.apiService
      .graphql<{ trialsByDay: DayAggregate[] }>(trialsByDayQuery)
      .then(r => r.trialsByDay)
  }

  async subscribersByMonth(): Promise<MonthAggregate[]> {
    return this.apiService
      .graphql<{ subscribersByMonth: MonthAggregate[] }>(
        subscribersByMonthQuery,
      )
      .then(r => r.subscribersByMonth)
  }

  updateRemovalUsage = (company: string, amount: number): Promise<boolean> => {
    return this.apiService
      .graphql<{ updateRemovalUsage: boolean }>(updateRemovalUsageMutation, {
        company,
        amount,
      })
      .then(r => r.updateRemovalUsage)
  }

  async canceledByMonth(): Promise<MonthAggregate[]> {
    return this.apiService
      .graphql<{ canceledByMonth: MonthAggregate[] }>(canceledByMonthQuery)
      .then(r => r.canceledByMonth)
  }
}
