import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { User } from '../entities/user'

const useStyles = makeStyles({})

interface OffersDataProps {
  user: User | undefined
}

export const OffersData: FunctionComponent<OffersDataProps> = props => {
  const { user } = props

  if (!user) return null

  return (
    <Grid item xs={4}>
      <Box padding={4} />
      <Typography variant='h5'>Dados Ofertas</Typography>
      <Box padding={3} />
      <Typography variant='h6'> Ofertas criadas </Typography>
      <Typography variant='body2'>
        {user.company?.numOffers || 'Nenhuma oferta criada'}
      </Typography>
      <Box padding={2} />
      <Typography variant='h6'> Produtos cadastrados </Typography>
      <Typography variant='body2'>
        {user.company?.numProducts || 'Nenhum produto cadastrado '}
      </Typography>
      <Box padding={2} />
    </Grid>
  )
}
