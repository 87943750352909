import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { Provider } from 'mobx-preact'
import { h, VNode } from 'preact'
import Router, { Route } from 'preact-router'
import { SnackbarController } from './components/snackbar'
import { Home } from './home'
import { LoginScreen } from './login/screen'
import { ProtectedRoute } from './protected'
import AppRoutes from './routes'
import { store } from './stores/root'
import './view/index.css'
import { theme } from './view/theme'

export function App(): VNode {
  return (
    <Provider {...store}>
      {''}
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Route path={AppRoutes.Login} component={LoginScreen} />
          <ProtectedRoute default path={AppRoutes.CatchAll} component={Home} />
        </Router>
      </MuiThemeProvider>
    </Provider>
  )
}
