import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { h } from 'preact'
import { route } from 'preact-router'
import { AuthStore } from '../stores/auth'
import { LayoutStore } from '../stores/layout'
import { MenuItem } from './items'

interface DrawerItemProps {
  item: MenuItem
  layout?: LayoutStore
  auth?: AuthStore
}

export function DrawerItem(props: DrawerItemProps): any {
  const { item, layout, auth } = props

  if (!auth) {
    throw new Error('Invalid props')
  }

  const { user } = auth

  if (!user) {
    throw new Error('Invalid user')
  }

  return (
    <ListItem
      button
      onClick={(): void => {
        route(item.route)
        layout && layout.toggleDrawer()
      }}>
      <ListItemAvatar>{item.icon}</ListItemAvatar>
      <ListItemText>
        <Typography variant='button'>{item.label}</Typography>
      </ListItemText>
    </ListItem>
  )
}
