import { Box, Container, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { ImageDropZone } from '../../components/dropzone'
import { getImageUrl } from '../../utils/url'
import { ImageEditState } from './state'

export const ImageEditForm: FunctionComponent<ImageEditState> = props => {
  const { image, onImageSelection } = props
  return (
    <Container maxWidth='md'>
      <Grid container direction='column'>
        <Box padding={3} />
        <Box padding={1} />
        <Box padding={3} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ImageDropZone onImageSelection={onImageSelection} />
          </Grid>
          <Grid item xs={6}>
            <Box textAlign='center'>
              {image.imageURL && (
                <img src={getImageUrl(image.imageURL, 180)} width={180} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
