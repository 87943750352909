import {
  Box,
  Chip,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { Fragment, FunctionComponent, h } from 'preact'
import { useState } from 'preact/hooks'
import { FlatButton, RaisedButton } from '../../components/button'
import { ImageDropZone } from '../../components/dropzone'
import { Header } from '../../components/header'
import { LinearLoadingIndicator } from '../../components/loading'
import uploadImage from '../../image/upload'
import { DTypes, locator } from '../../infra/registry'
import { store } from '../../stores/root'
import { Background } from '../entities/background'
import { BackgroundProvider } from '../providers/background'

const formats = ['stories', 'feed', 'square']

export const BackgroundCreateScreen: FunctionComponent<any> = () => {
  const [files, setFiles] = useState<any>({})
  const [uploading, setUploading] = useState(false)
  const [background, setBackground] = useState<Background>({})
  const [tag, setTag] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const [busy, setBusy] = useState<boolean>(false)

  const backgroundProvider = locator.get<BackgroundProvider>(
    DTypes.BackgroundProvider,
  )

  const handleTagDelete = (tag: string): void => {
    tags.splice(tags.indexOf(tag), 1)
    setTags([...tags])
  }

  const resetForm = (): void => {
    setBackground({})
    setFiles({})
    setTags([])
  }

  const handleSave = async (): Promise<void> => {
    if (tags.length === 0) {
      return store.layout.showSnackbar('Define tags', true)
    }

    for (const format of formats) {
      if (!files[format]) {
        return store.layout.showSnackbar('Envie todos os arquivos', true)
      }
    }

    try {
      setBusy(true)
      background.tags = tags.join(',')

      for (const format of formats) {
        background.imageURL = files[format]
        background.format = format
        await backgroundProvider.update({ ...background })
      }

      resetForm()
    } catch (e) {
      store.layout.showSnackbar(e.message)
    } finally {
      setBusy(false)
    }
  }

  const handleFileSelection =
    (name: string) =>
    async (file: File): Promise<void> => {
      try {
        setUploading(true)
        const imageURL = await uploadImage(file, 'background')
        setFiles({ ...files, [name]: imageURL })
      } catch (e) {
        store.layout.showSnackbar('Erro ao enviar imagem', true)
      } finally {
        setUploading(false)
      }
    }

  return (
    <Container maxWidth='md'>
      <Header title='Artes' description='Envie e edite suas artes'>
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          {busy ? (
            <LinearProgress />
          ) : (
            <RaisedButton primary label='Enviar Arte' onClick={handleSave} />
          )}
        </Grid>
      </Header>
      <Box padding={2} />
      {busy && <LinearLoadingIndicator />}
      {!busy && (
        <Grid container spacing={3}>
          <Grid container spacing={2} direction='column' item xs={12}>
            <Grid container item>
              {tags.length > 0 &&
                tags.map(t => (
                  <Box key={t} padding={1}>
                    <Chip label={t} onDelete={handleTagDelete} />
                  </Box>
                ))}
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                placeholder='Digite uma tag e pressione ENTER'
                value={tag}
                onChange={(e): void => setTag(e.target.value)}
                onKeyPress={(e): void => {
                  if (e.key === 'Enter') {
                    if (tags.includes(tag)) return
                    tags.push(tag)
                    setTag('')
                  }
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Box padding={3} />
      <Grid container spacing={2} justifyContent='space-between'>
        {formats.map(f => (
          <Grid key={f} item xs={4}>
            {files[f] ? (
              <Grid container direction='column' alignItems='center'>
                <img src={files[f]} width={200} />
                <Box padding={1} />
                <FlatButton
                  onClick={(): void => {
                    const fs = Object.assign({}, files)
                    delete fs[f]
                    setFiles(fs)
                  }}
                  label='Remover'
                />
              </Grid>
            ) : uploading ? (
              <LinearProgress />
            ) : (
              <Fragment>
                <Typography variant='h6'>{f.toUpperCase()}</Typography>
                <Box padding={1} />
                <ImageDropZone onImageSelection={handleFileSelection(f)} />
              </Fragment>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
