import { Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { TagEditForm } from './form'
import { TagEditHeader } from './header'
import { useTagEditState } from './state'

export const TagEditScreen: FunctionComponent<any> = props => {
  const { matches } = props
  const state = useTagEditState(matches.key)
  return (
    <Container>
      <TagEditHeader busy={state.busy} onSave={state.onSave} />
      <TagEditForm {...state} />
    </Container>
  )
}
