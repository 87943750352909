import { useEffect, useState } from 'preact/hooks'
import { DTypes, locator } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { Tag } from '../entities/tag'
import { TagProvider } from '../providers/tag'

export interface TagListState {
  tags: Tag[]
  path?: string
}

export const useTagListState = (): TagListState => {
  const [tags, setTags] = useState<Tag[]>([])

  const tagService = locator.get<TagProvider>(DTypes.TagProvider)
  const store = locator.get<RootStore>(DTypes.Store)

  useEffect(() => {
    const fetchTags = async (): Promise<void> => {
      try {
        const tags = await tagService.tags()
        setTags(tags)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar tags', true)
      }
    }

    fetchTags()
  }, [])

  return { tags }
}
