export const fetchProductsQuery = `
  query Products {
    products {
      _id
      _key
      _rev
      name
      imageURL
      height
      width
      posX
      posY
    }
  }
`

export const fetchProductQuery = `
  query Product($id: String!) {
    product(id: $id) {
      _id
      _key
      _rev
      name
      imageURL
      height
      width
      tags
      posX
      posY
    }
  }
`

export const FetchProductsReviewQuery = `
  query ProductsToReview {
    productsToReview {
      _id
      _key
      name
      imageURL
    }
  }
`

export const similarProductsQuery = `
  query similarProducts($name: String!) {
    similarProducts(name: $name) {
      name
      imageURL
    }
  }
`
