import { injectable } from 'inversify'
import { Constants } from '../constants'
import { RootStore } from '../stores/root'
import { User } from '../users/entities/user'
import { apiRootUrl } from './hostname'
import { DTypes, locator } from './registry'

export interface AuthenticateResponse {
  token: string
  user: User
}

export interface GraphQLResponse<T> {
  errors: string[]
  data: T
}

@injectable()
export class ApiService {
  authenticate = async (
    email: string,
    password: string,
  ): Promise<AuthenticateResponse> => {
    return this.post<GraphQLResponse<AuthenticateResponse>>(
      `${apiRootUrl}/authenticate`,
      {
        email,
        password,
      },
    ).then(r => r.data)
  }

  graphql = async <T>(
    request: string,
    payload?: Record<string, any>,
  ): Promise<T> => {
    return this.post<GraphQLResponse<T>>(`${apiRootUrl}/graphql`, {
      query: request,
      variables: payload,
    }).then(r => r.data)
  }

  get = <T>(url: string): Promise<T> => {
    return this.request<T>(url, 'GET')
  }

  post = <T>(url: string, payload: Record<string, any>): Promise<T> => {
    return this.request<T>(url, 'POST', payload)
  }

  checkAuthError = (response: Response): void => {
    const store = locator.get<RootStore>(DTypes.Store)
    response.status === 401 && store.auth.logout()
  }

  insertTokenIntoHeaders = (headers: Headers): void => {
    const token = localStorage.getItem(Constants.Token)

    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    if (token) {
      headers.append('Authorization', token)
    }
  }

  request = async <T>(
    url: string,
    method: string,
    payload?: Record<string, any>,
  ): Promise<T> => {
    const headers = new Headers()
    this.insertTokenIntoHeaders(headers)

    const params = {
      method,
      headers,
      body: JSON.stringify(payload),
    }

    const response = await fetch(`${url}`, params)
    this.checkAuthError(response)
    const jsonResponse = await response.json()

    if (jsonResponse.errors) {
      const error = jsonResponse.errors[0]
      const errorMessage = new Error(error.message)

      const store = locator.get<RootStore>(DTypes.Store)

      store?.layout?.showSnackbar(error.message, true)

      if (error.status === 401) {
        store.auth.logout()
        throw errorMessage
      } else {
        throw errorMessage
      }
    }

    return jsonResponse
  }
}
