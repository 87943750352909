import { Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { ImageEditForm } from './form'
import { ImageEditHeader } from './header'
import { useImageEditState } from './state'

export const ImageEditScreen: FunctionComponent<any> = props => {
  const { matches } = props
  const state = useImageEditState(matches.key)
  return (
    <Container>
      <ImageEditHeader busy={state.busy} onSave={state.onSave} />
      <ImageEditForm {...state} />
    </Container>
  )
}
