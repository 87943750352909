import { h } from 'preact'
import { useTheme, Theme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

interface SpacerProps {
  size?: number
  double?: boolean
  half?: boolean
  horizontal?: boolean
}

export function Spacer(props: SpacerProps): any {
  const theme: Theme = useTheme()
  const spacing = theme.spacing()

  let { size } = props
  const { double, half, horizontal = false } = props

  size = double ? spacing * 3 : half ? spacing / 2 : size || spacing

  return horizontal ? (
    <Grid style={{ width: size }} />
  ) : (
    <Grid style={{ height: size }} />
  )
}
