import { Typography } from '@material-ui/core'
import { h } from 'preact'
import { Colors } from '../view/colors'

export function TypoDescription({ text }: { text: string }): any {
  return (
    <Typography
      style={{
        color: Colors.textDescriptionColor,
        fontWeight: 400,
        fontSize: '0.875rem',
        letterSpacing: '.0142857143em',
      }}
      variant='body2'>
      {text}
    </Typography>
  )
}

export function TypoMain({ text }: { text: string }): any {
  return (
    <Typography
      style={{
        color: Colors.textDescriptionColor,
        fontFamily: 'GoogleSans, Roboto, Arial',
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: '.00625em',
        lineHeight: '1.5rem',
      }}
      variant='body2'>
      {text}
    </Typography>
  )
}
