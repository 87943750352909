import { DateTime } from 'luxon'
import { injectable } from 'inversify'

const weekdays = new Array(7)
weekdays[0] = 'Segunda-Feira'
weekdays[1] = 'Terça-Feira'
weekdays[2] = 'Quarta-Feira'
weekdays[3] = 'Quinta-Feira'
weekdays[4] = 'Sexta-Feira'
weekdays[5] = 'Sábado'
weekdays[6] = 'Domingo'

const shortWeekdays = new Array(7)
shortWeekdays[0] = 'Seg'
shortWeekdays[1] = 'Ter'
shortWeekdays[2] = 'Qua'
shortWeekdays[3] = 'Qui'
shortWeekdays[4] = 'Sex'
shortWeekdays[5] = 'Sáb'
shortWeekdays[6] = 'Dom'

export const monthNames = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
]

@injectable()
export class DateProvider {
  static dateFormat = 'dd/mm/yyyy'
  static dateTimeFormat = 'dd/mm/yyyy hh:mm:ss'

  fromISOToDisplay(date: string, time?: boolean): string {
    const dt = DateTime.fromISO(date).toUTC()
    return dt
      .toLocal()
      .toLocaleString(time ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT)
  }

  fromTimestampToDisplay(timestamp: number, time?: boolean): string {
    const dt = DateTime.fromSeconds(timestamp).toUTC()
    return dt
      .toLocal()
      .toLocaleString(time ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT)
  }
}
