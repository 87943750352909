import {
  Grid,
  Snackbar as MaterialSnackbar,
  Typography,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { inject, observer } from 'mobx-preact'
import { h, VNode } from 'preact'
import { LayoutStore } from '../stores/layout'

interface SnackbarProps {
  layout?: LayoutStore
}

export function Snackbar(props: SnackbarProps): VNode {
  const { layout } = props

  if (!layout) {
    throw new Error('Invalid props')
  }

  const icon = layout.snackbar.alert ? (
    <ReportProblemOutlinedIcon style={{ color: '#ffd600' }} />
  ) : (
    <InfoOutlinedIcon style={{ color: '#00c853' }} />
  )

  return (
    <MaterialSnackbar
      open={layout.snackbar.open || false}
      onClose={(): any => setTimeout(layout.hideSnackbar, 4000)}
      message={
        <Grid container alignItems='center'>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography
              style={{ marginLeft: 16 }}
              color='inherit'
              variant='body1'>
              {layout.snackbar.message}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  )
}

export const SnackbarController = inject('layout')(observer(Snackbar))
