import { apiRootUrl } from '../infra/hostname'

export default async function uploadImage(
  file: File,
  type: string,
  key?: string | undefined,
): Promise<string | undefined> {
  try {
    const formData = new FormData()
    formData.append('photo', file)

    const destinationUrl = key
      ? `${apiRootUrl}/upload/${type}?key=${key}`
      : `${apiRootUrl}/upload/${type}`

    const url = await fetch(destinationUrl, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
      method: 'POST',
      body: formData,
    })
      .then(r => r.json())
      .then(r => r.data)

    return url
  } catch (e) {
    console.log(e)
  }
}
