import { Box, Container, Divider, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { useState } from 'preact/hooks'
import { Company } from '../../company/entities/company'
import { CompanyProvider } from '../../company/providers/company'
import { Header } from '../../components/header'
import { LinearLoadingIndicator } from '../../components/loading'
import { SearchField } from '../../components/search_field'
import { DTypes, locator } from '../../infra/registry'
import { User } from '../entities/user'
import { UserProvider } from '../providers/user'
import { UserItem } from './items'

export const UserListScreen: FunctionComponent = () => {
  const [busy, setBusy] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const companyProvider = locator.get<CompanyProvider>(DTypes.CompanyProvider)
  const userProvider = locator.get<UserProvider>(DTypes.UserProvider)

  const onSearch = async (email: string): Promise<void> => {
    try {
      setBusy(true)
      const users = await userProvider.usersByEmail(email.replace(/ /g, ''))
      setUsers(users)
    } catch (e) {
      console.log(e)
    } finally {
      setBusy(false)
    }
  }

  const handleRemoveSubscription = async (company: Company): Promise<any> => {
    await companyProvider.update({
      _id: company._id,
      _key: company._key,
      _rev: company._rev,
      isTrial: true,
      subscription: null,
    } as any)
    onSearch('')
  }

  const handleChangeUserStripeId = async (
    userId: string,
    stripeID: string,
  ): Promise<void> => {
    if (!userId) return
    await userProvider.updateStripeId(userId, stripeID)
    onSearch('')
  }

  const handleActiveSubscription = async (company: Company): Promise<any> => {
    if (!company._id) return
    await companyProvider.activateSubscription(company._id)
    onSearch('')
  }

  const handleChangeRemovalsAmount = async (
    company: Company,
    amount: number,
  ) => {
    if (!company._id) return
    await companyProvider.updateRemovalUsage(company._id, amount)
  }

  return (
    <Container>
      <Header title='Contas' description='Gerencie as contas do Oferta Fácil'>
        <Grid container justifyContent='flex-end' alignItems='flex-end'></Grid>
      </Header>
      <Grid
        container
        style={{
          padding: 16,
        }}>
        <Grid item xs={12}>
          <SearchField
            placeholder='Digite o e-mail do usuário'
            onClear={(): any => onSearch('')}
            onSearch={onSearch}
          />
          <Box height={32} />
          {busy && <LinearLoadingIndicator />}
          <Divider />
          {users.length > 0 &&
            users.map(user => (
              <UserItem
                key={user._key}
                user={user}
                handleChangeRemovalsAmount={handleChangeRemovalsAmount}
                handleChangeUserStripeId={handleChangeUserStripeId}
                handleActiveSubscription={handleActiveSubscription}
                handleRemoveSubscription={handleRemoveSubscription}
              />
            ))}
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Container>
  )
}
