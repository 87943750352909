import { useEffect, useState } from 'preact/hooks'
import uploadImage from '../../image/upload'
import { DTypes, locator } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { Tag } from '../entities/tag'
import { TagProvider } from '../providers/tag'

export interface TagEditState {
  busy: boolean
  onFieldChange: any
  onImageSelection: (file: File) => void
  onSave: any
  tag: Tag
}

export const useTagEditState = (key?: string): TagEditState => {
  const [tag, setTag] = useState<Tag>({})
  const [busy, setBusy] = useState(false)

  const tagProvider = locator.get<TagProvider>(DTypes.TagProvider)
  const store = locator.get<RootStore>(DTypes.Store)

  useEffect(() => {
    const fetchTag = async (): Promise<void> => {
      try {
        const tag = await tagProvider.tag(key as string)
        setTag(tag)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar a tag', true)
      }
    }
    if (key) {
      fetchTag()
    }
  }, [])

  const onImageSelection = async (file: File): Promise<void> => {
    try {
      const imageURL = await uploadImage(file, 'tag')
      setTag({ ...tag, imageURL })
    } catch (e) {
      store.layout.showSnackbar('Erro ao enviar a imagem', true)
    }
  }

  const onSave = async (): Promise<void> => {
    if (!tag.name || !tag.imageURL) {
      return store.layout.showSnackbar('Preencha todos os campos', true)
    }

    try {
      setBusy(true)

      await tagProvider.update(tag)
      store.layout.showSnackbar('Tag salva com sucesso')

      if (!key) {
        setTag({})
      }

      setBusy(false)
    } catch (error) {
      setBusy(false)
      store.layout.showSnackbar('Erro ao salvar', true)
    }
  }

  const onFieldChange = (name: string) => (e: any): void => {
    setTag({
      ...tag,
      [name]: e.target.value,
    })
  }

  return { tag, onSave, busy, onFieldChange, onImageSelection }
}
