import { hostname } from '../infra/hostname'

export const getImageUrl = (url: string, size?: number): string => {
  if (!url) return ''
  const idx = url.lastIndexOf('/')
  const fileName = url.substring(idx + 1)
  const parts = fileName.split('.')
  const file = parts[0]
  const ext = parts[1]

  const host =
    hostname === 'localhost'
      ? 'https://easyoffer-uploads-dev.s3.amazonaws.com'
      : 'https://d2bxayf79dnwor.cloudfront.net'

  if (size) {
    return `${host}/${file}-${size || 180}w.${ext}`
  }
  return `${host}/${file}.${ext}`
}
