import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { FunctionComponent, h } from 'preact'
import { route } from 'preact-router'
import AppRoutes from '../../routes'
import { getStatusDisplayName, Order } from '../entities/order'

interface Props {
  orders: Order[]
}

export const OrderList: FunctionComponent<Props> = props => {
  const { orders } = props

  return (
    <Grid container spacing={1}>
      {orders.map(order => {
        const created = DateTime.fromISO(order.createdAt as string)
        return (
          <ListItem
            onClick={(): any =>
              route(AppRoutes.OrdersView.replace(':key', order._key as string))
            }
            key={order._id}
            button>
            <ListItemAvatar>
              <Avatar src={`/assets/colors/${order.palette}.png`} />
            </ListItemAvatar>
            <Grid item xs={12}>
              <Typography variant='body1'>Pedido: #{order._key}</Typography>
              <Typography variant='body1'>{order.theme}</Typography>
              <Typography variant='caption'>
                {created.toFormat('dd/MM/yyyy hh:mm:ss')}
              </Typography>
              <Typography variant='h6'>
                {getStatusDisplayName(order.status)}
              </Typography>
            </Grid>
          </ListItem>
        )
      })}
    </Grid>
  )
}
