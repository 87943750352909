import {
  Box,
  Typography,
  Grid,
  Switch,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Fragment, FunctionComponent, h } from 'preact'
import { UserDetailsState } from './state'

export const SignatureData: FunctionComponent<UserDetailsState> = props => {
  const { user, setUser, formatDateTime } = props

  if (!user) return null

  return (
    <Grid item xs={4}>
      <Typography variant='h5'>Assinatura</Typography>
      <Box padding={3} />
      <Typography variant='h6'>Trial </Typography>
      <Switch
        color='primary'
        value={user.company?.isTrial}
        onChange={(e): void => {
          if (!user.company) return
          user.company.isTrial = e.target.checked
          setUser({ ...user })
        }}
      />
      <Box padding={3} />
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='h6'>Assinatura</Typography>
        </Grid>
        <Grid item>
          {user.company?.subscription && (
            <Fragment>
              <Select
                variant='outlined'
                value={user.company.subscription.status}
                onChange={(e): void => {
                  if (!user.company || !user.company.subscription) return
                  user.company.subscription.status = e.target.value as any
                  setUser({ ...user })
                }}>
                <MenuItem value={'active'}>Ativa</MenuItem>
                <MenuItem value={'past_due'}>Vencida</MenuItem>
                <MenuItem value={'unpaid'}>Sem pagamento</MenuItem>
                <MenuItem value={'canceled'}>Cancelada</MenuItem>
                <MenuItem value={'incomplete'}>Incompleta</MenuItem>
              </Select>
            </Fragment>
          )}
        </Grid>
      </Grid>
      <Box padding={2} />
      <Typography variant='h6'>Início do Trial</Typography>
      <Typography variant='body2'>
        {formatDateTime(user.company?.trialStart || '')}
      </Typography>
      <Box padding={2} />
      <Typography variant='h6'>Fim do trial</Typography>
      <TextField
        onChange={(e): void => {
          if (!user.company) return
          user.company.trialEnd = e.target.value
          setUser({ ...user })
        }}
        variant='outlined'
        value={user.company?.trialEnd}
      />
    </Grid>
  )
}
