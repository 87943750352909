import { Grid, Typography, Divider, makeStyles } from '@material-ui/core'
import { h, Fragment } from 'preact'
import { Colors } from '../view/colors'
import { Spacer } from './spacer'
import { TypoDescription } from './typography'

interface HeaderProps {
  leading?: any
  title: string
  description?: string
  children?: any
}

const useStyles = makeStyles({
  root: {
    marginBottom: `1px solid ${Colors.dividerColor}`,
  },
  spacer: {
    height: 48,
    width: 0,
  },
})

function IconSpacer(): any {
  const classes = useStyles()
  return <Grid className={classes.spacer} />
}

export function Header(props: HeaderProps): any {
  const classes = useStyles()
  const { leading, title, description, children } = props
  return (
    <Grid className={classes.root}>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={7}>
          <Grid container alignItems='center'>
            {leading || <IconSpacer />}
            <Typography variant='h4'>{title}</Typography>
          </Grid>
          {description && (
            <Fragment>
              <Spacer half />
              <TypoDescription text={description} />
            </Fragment>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={5}
          justifyContent='flex-end'
          alignItems='flex-end'>
          {children}
        </Grid>
      </Grid>
      <Spacer double />
      <Divider />
    </Grid>
  )
}
