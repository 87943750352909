import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { DTypes, locator } from '../../infra/registry'
import { OFCharge } from '../entities/charge'
import { OFSubscription } from '../entities/subscription'
import {
  cancelChargeMutation,
  cancelSubscriptionMutation,
} from '../graphql/mutations'
import { chargesQuery, subscriptionQuery } from '../graphql/queries'

@injectable()
export class PaymentProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  async subscription(id: string): Promise<OFSubscription> {
    return this.apiService
      .graphql<{ subscription: OFSubscription }>(subscriptionQuery, { id })
      .then(r => r.subscription)
  }

  async charges(sub: string): Promise<OFCharge[]> {
    return this.apiService
      .graphql<{ charges: OFCharge[] }>(chargesQuery, { sub })
      .then(r => r.charges)
  }

  async cancelSubscription(sub: string): Promise<boolean> {
    return this.apiService
      .graphql<{ cancelSubscription: boolean }>(cancelSubscriptionMutation, {
        sub,
      })
      .then(r => r.cancelSubscription)
  }

  async cancelCharge(sub: string): Promise<boolean> {
    return this.apiService
      .graphql<{ cancelCharge: boolean }>(cancelChargeMutation, { sub })
      .then(r => r.cancelCharge)
  }
}
