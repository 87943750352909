import { Grid, Link } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { route } from 'preact-router'
import AppRoutes from '../../routes'
import { getImageUrl } from '../../utils/url'
import { Background } from '../entities/background'

interface BackgroundItemProps {
  background: Background
  onSave: Function
}

export const BackgroundItem: FunctionComponent<BackgroundItemProps> = props => {
  const { background } = props

  return (
    <Grid item>
      <Link
        onClick={(): boolean =>
          route(
            AppRoutes.EditBackground.replace(
              ':key?',
              background._key as string,
            ),
          )
        }>
        <img
          style={{ cursor: 'pointer' }}
          src={getImageUrl(background.imageURL || '', 180)}
        />
      </Link>
    </Grid>
  )
}
