import {
  Box,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import {
  LayersClearOutlined,
  SearchOutlined,
  UndoOutlined,
} from '@material-ui/icons'
import { Fragment, FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { RaisedButton } from '../../components/button'
import { LinearLoadingIndicator } from '../../components/loading'
import { apiRootUrl } from '../../infra/hostname'
import { DTypes, locator } from '../../infra/registry'
import { store } from '../../stores/root'
import { blobToFile } from '../../utils/toFile'
import { getImageUrl } from '../../utils/url'
import { Colors } from '../../view/colors'
import { Product } from '../entities/product'
import { ProductProvider } from '../providers/product'

export const ProductReviewScreen: FunctionComponent = () => {
  const [name, setName] = useState<string>('')
  const [product, setProduct] = useState<Product | null>(null)
  const [image, setImage] = useState<any>('')
  const [busy, setBusy] = useState(false)

  const [similarProducts, setSimilarProducts] = useState<Product[] | null>(null)

  const productProvider = locator.get<ProductProvider>(DTypes.ProductProvider)

  const fetchSimilarProducts = async (name: string): Promise<void> => {
    try {
      const products = await productProvider.similar(name)
      setSimilarProducts(products)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    }
  }

  const fetchProduct = async (): Promise<void> => {
    try {
      setBusy(true)

      const product = await productProvider.productsToReview()
      if (product.name?.trim()) {
        fetchSimilarProducts(product.name)
      }
      setName(product.name?.trim() as string)
      setProduct(product)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [])

  const removeBackground = async (url: string): Promise<void> => {
    setBusy(true)

    const image = await fetch(url, {
      mode: 'cors',
    }).then(r => r.blob())

    const formData = new FormData()
    formData.append('file', image)

    try {
      const noBgImage = await fetch(`${apiRootUrl}/removebg`, {
        method: 'POST',
        body: formData,
      }).then(r => r.text())
      setImage(noBgImage)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setBusy(false)
    }
  }

  const reviewProduct = (status: string) => async (): Promise<void> => {
    if (!product) return

    try {
      setBusy(true)

      if (image) {
        const imageString = `data:image/png;base64, ${image}`
        const imageBlob = await fetch(imageString).then(r => r.blob())

        const formData = new FormData()
        formData.append('photo', blobToFile(imageBlob, 'product-image.png'))

        const imageURL = await fetch(`${apiRootUrl}/product/upload`, {
          method: 'POST',
          body: formData,
        })
          .then(r => r.json())
          .then(r => r.data)

        product.imageURL = imageURL
      }

      await productProvider.review(
        Object.assign(product, { name: name.trim() }),
        status,
      )
      fetchProduct()
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setImage(null)
      setBusy(false)
    }
  }

  if (!product) {
    return (
      <Box padding={3}>
        <LinearLoadingIndicator />
      </Box>
    )
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={6}>
          <Grid container direction='column'>
            <Grid
              style={{
                backgroundColor: Colors.textInputColor,
                borderRadius: 8,
                padding: 8,
              }}
              container
              justifyContent='center'
              item
              xs={12}>
              <img
                src={
                  image
                    ? `data:image/png;base64, ${image}`
                    : getImageUrl(product.imageURL as string)
                }
                width={450}
              />
              <Grid container justifyContent='space-around'>
                <IconButton
                  onClick={(): any =>
                    removeBackground(product.imageURL as string)
                  }>
                  <LayersClearOutlined />
                </IconButton>
                <IconButton onClick={(): any => setImage(null)}>
                  <UndoOutlined />
                </IconButton>
              </Grid>
            </Grid>
            {busy ? (
              <LinearLoadingIndicator />
            ) : (
              <Fragment>
                <Box padding={1} />
                <TextField
                  value={name}
                  onChange={(e): void => setName(e.target.value)}
                  variant='outlined'
                  defaultValue={product.name}
                  onKeyPress={(e): void => {
                    if (e.key === 'Enter') {
                      fetchSimilarProducts(name)
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={(): Promise<void> =>
                            fetchSimilarProducts(name)
                          }>
                          <SearchOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box padding={1} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RaisedButton
                      label='Rejeitar'
                      fullWidth
                      onClick={reviewProduct('rejected')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RaisedButton
                      primary
                      label='Aceitar'
                      fullWidth
                      onClick={reviewProduct('approved')}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction='column'>
            <Grid item>
              <Box padding={2}>
                <Typography variant='h6' align='center'>
                  Produtos Semelhantes
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box padding={2}>
                {similarProducts &&
                  similarProducts.map(p => (
                    <ListItem key={p.imageURL}>
                      <ListItemAvatar>
                        <img
                          width={60}
                          src={getImageUrl(p.imageURL as string, 180)}
                        />
                      </ListItemAvatar>
                      <ListItemText>
                        <Box padding={1}>
                          <Typography variant='body2'>{p.name}</Typography>
                        </Box>
                      </ListItemText>
                    </ListItem>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
