import { Product } from '../entities/product'
import { useState, useEffect } from 'preact/hooks'
import { locator, DTypes } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { ProductProvider } from '../providers/product'

export interface ProductListState {
  products: Product[]
}

export const useProductListState = (): ProductListState => {
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    const store = locator.get<RootStore>(DTypes.Store)
    const productService = locator.get<ProductProvider>(DTypes.ProductProvider)

    const fetchProducts = async (): Promise<void> => {
      try {
        const products = await productService.products()
        setProducts(products)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar produtos', true)
      }
    }

    fetchProducts()
  }, [])

  return { products }
}
