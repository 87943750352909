import { Box, Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { ImageListHeader } from './header'
import { ImageList } from './list'
import { useImageListState } from './state'

export const ImageListScreen: FunctionComponent = () => {
  const state = useImageListState()
  return (
    <Container>
      <ImageListHeader />
      <Box padding={3} />
      <ImageList {...state} />
    </Container>
  )
}
