import { Box, Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { BackgroundListHeader } from './header'
import { BackgroundList } from './list'
import { useBackgroundListState } from './state'

export const BackgroundListScreen: FunctionComponent<{}> = () => {
  const state = useBackgroundListState()
  return (
    <Container>
      <BackgroundListHeader />
      <Box padding={3} />
      <BackgroundList {...state} />
    </Container>
  )
}
