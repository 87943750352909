import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { Model } from '../../infra/model'
import { DTypes, locator } from '../../infra/registry'
import { AdminStats, User } from '../entities/user'
import {
  updateStripeIdMutation,
  updateUserMutation,
} from '../graphql/mutations'
import {
  fetchAdminStatsQuery,
  fetchUserQuery,
  fetchUsersQuery,
  subscribedUsersQuery,
  usersByEmailQuery,
} from '../graphql/queries'

@injectable()
export class UserProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  async users(): Promise<User[]> {
    return this.apiService
      .graphql<{ users: User[] }>(fetchUsersQuery)
      .then(r => r.users)
  }

  async user(id: string): Promise<User> {
    return this.apiService
      .graphql<{ user: User }>(fetchUserQuery, { id })
      .then(r => r.user)
  }

  async usersByEmail(email: string): Promise<User[]> {
    return this.apiService
      .graphql<{ usersByEmail: User[] }>(usersByEmailQuery, { email })
      .then(r => r.usersByEmail)
  }

  async subscribed(): Promise<User[]> {
    return this.apiService
      .graphql<{ subscribedUsers: User[] }>(subscribedUsersQuery)
      .then(r => r.subscribedUsers)
  }

  async adminStats(date: number): Promise<AdminStats> {
    return this.apiService
      .graphql<{ adminStatsUsers: AdminStats }>(fetchAdminStatsQuery, { date })
      .then(r => r.adminStatsUsers)
  }

  update = (data: User): Promise<Model> => {
    return this.apiService
      .graphql<{ updateUser: User }>(updateUserMutation, { data })
      .then(r => r.updateUser)
  }

  updateStripeId = (id: string, stripeId: string): Promise<Model> => {
    return this.apiService
      .graphql<{ updateStripeId: Model }>(updateStripeIdMutation, {
        id,
        stripeId,
      })
      .then(r => r.updateStripeId)
  }
}
