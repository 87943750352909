import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { Model } from '../../infra/model'
import { DTypes, locator } from '../../infra/registry'
import { Background } from '../entities/background'
import { updateBackgroundMutation } from '../graphql/mutations'
import { fetchBackgroundQuery, fetchBackgroundsQuery } from '../graphql/queries'

@injectable()
export class BackgroundProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  backgrounds = async (): Promise<Background[]> => {
    return this.apiService
      .graphql<{ backgrounds: Background[] }>(fetchBackgroundsQuery)
      .then(r => r.backgrounds)
  }

  background = async (id: string): Promise<Background> => {
    return this.apiService
      .graphql<{ background: Background }>(fetchBackgroundQuery, { id })
      .then(r => r.background)
  }

  update = async (data: Background): Promise<Model> => {
    return this.apiService
      .graphql<{ updateBackground: Model }>(updateBackgroundMutation, { data })
      .then(r => r.updateBackground)
  }
}
