import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { Model } from '../../infra/model'
import { DTypes, locator } from '../../infra/registry'
import { Image } from '../entities/image'
import { updateImageMutation } from '../graphql/mutations'
import { fetchImageQuery, fetchImagesQuery } from '../graphql/queries'

@injectable()
export class ImageProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  images = async (): Promise<Image[]> => {
    return this.apiService
      .graphql<{ images: Image[] }>(fetchImagesQuery)
      .then(r => r.images)
  }

  image = async (id: string): Promise<Image> => {
    return this.apiService
      .graphql<{ image: Image }>(fetchImageQuery, { id })
      .then(r => r.image)
  }

  update = async (data: Image): Promise<Model> => {
    return this.apiService
      .graphql<{ updateImage: Model }>(updateImageMutation, { data })
      .then(r => r.updateImage)
  }
}
