import { CircularProgress, Grid } from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { FunctionComponent, h } from 'preact'
import { route } from 'preact-router'
import { useEffect, useState } from 'preact/hooks'
import AuthStore from './auth/store'
import { Constants } from './constants'
import { DTypes, locator } from './infra/registry'
import AppRoutes from './routes'
import { UserProvider } from './users/providers/user'

export const Redirect: FunctionComponent<{ to: string }> = props => {
  useEffect(() => {
    route(props.to)
  }, [props.to])

  return null
}

interface ProtectedProps {
  path: string
  component: any
  auth?: AuthStore
  default?: boolean
  matches?: any
  url?: string
}

const Protected: FunctionComponent<ProtectedProps> = ({
  component: C,
  auth,
  ...rest
}) => {
  const [token, setToken] = useState<string | null>(null)

  if (!auth) {
    throw new Error('Invalid props')
  }

  const storage = locator.get<Storage>(DTypes.Storage)
  const userService = locator.get<UserProvider>(DTypes.UserProvider)

  useEffect(() => {
    const updateUserInfo = async (): Promise<any> => {
      const storedUser = storage.getItem(Constants.User)
      if (!storedUser) {
        return route(AppRoutes.Login)
      }

      const user = await userService.user(JSON.parse(storedUser)._id)

      if (!user.staff) {
        return route(AppRoutes.Login)
      }

      auth.setCredentials(user, token as string)
    }

    if (!token) {
      updateUserInfo()
    }
  }, [token])

  const storedToken = storage.getItem(Constants.Token)

  if (!storedToken) {
    return <Redirect to={AppRoutes.Login} />
  }

  setToken(storedToken)

  if (!auth.user) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return <C path={rest.path} url={rest.url} matches={rest.matches} />
}

export const ProtectedRoute = inject('auth')(observer(Protected))
