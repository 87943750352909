export const getStatusDisplayName = (status: string): string => {
  switch (status) {
    case 'active':
      return 'Ativo'
    case 'canceled':
      return 'Cancelados'
    case 'incomplete':
      return 'Aguardando pagamento'
    case 'unpaid':
      return 'Não pago'
    case 'past_due':
      return 'Atrasado'
    default:
      return ''
  }
}
