import {
  AppBar as MaterialAppBar,
  IconButton,
  makeStyles,
  Toolbar,
} from '@material-ui/core'
import ExitApp from '@material-ui/icons/ExitToAppOutlined'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import { inject, observer } from 'mobx-preact'
import { h } from 'preact'
import { AuthStore } from '../stores/auth'
import { LayoutStore } from '../stores/layout'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

interface EasyOfferAppBarProps {
  auth?: AuthStore
  layout?: LayoutStore
}

function EasyOfferAppBarComponent(props: EasyOfferAppBarProps): any {
  const { layout, auth } = props
  const classes = useStyles()

  if (!layout || !auth) {
    return null
  }

  return (
    <MaterialAppBar className={classes.root} elevation={0} position='static'>
      <Toolbar>
        <IconButton
          onClick={layout.toggleDrawer}
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <div style={{ flex: 1 }} />
        <IconButton onClick={auth.logout}>
          <ExitApp />
        </IconButton>
      </Toolbar>
    </MaterialAppBar>
  )
}

export const EasyOfferAppBar = inject(
  'layout',
  'auth',
)(observer(EasyOfferAppBarComponent))
