import { Box, Container } from '@material-ui/core'
import { h, FunctionComponent } from 'preact'
import { Header } from '../components/header'
import { AccountStats } from './stats'

export const ReportScreen: FunctionComponent = () => {
  return (
    <Container>
      <Header title='Relatórios' />
      <Box padding={2} />
      <AccountStats />
    </Container>
  )
}
