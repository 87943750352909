import {
  GridList,
  GridListTile,
  makeStyles,
  GridListTileBar,
} from '@material-ui/core'
import { FunctionComponent, h, VNode } from 'preact'
import { route } from 'preact-router'
import AppRoutes from '../../routes'
import { getImageUrl } from '../../utils/url'
import { ProductListState } from './state'

const useStyles = makeStyles({
  image: {
    objectFit: 'contain',
    cursor: 'pointer',
  },
})

export const ProductList: FunctionComponent<ProductListState> = props => {
  const { products } = props
  const classes = useStyles()
  return (
    <GridList cellHeight={120} cols={8}>
      {products &&
        products.map(
          (s): VNode => (
            <GridListTile key={s._id}>
              <img
                onClick={(): boolean =>
                  route(
                    AppRoutes.ProductEdit.replace(':key?', s._key as string),
                  )
                }
                className={classes.image}
                src={getImageUrl(s.imageURL as string, 180)}
              />
              <GridListTileBar title={s.name} />
            </GridListTile>
          ),
        )}
    </GridList>
  )
}
