import {
  Divider,
  Drawer as MaterialDrawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { inject, observer } from 'mobx-preact'
import { h } from 'preact'
import { Spacer } from '../components/spacer'
import { AuthStore } from '../stores/auth'
import { LayoutStore } from '../stores/layout'
import { DrawerItem } from './item'
import { menuItems } from './items'

const useStyles = makeStyles(() => ({
  list: {
    width: 300,
  },
}))

interface DrawerProps {
  layout?: LayoutStore
  auth?: AuthStore
}

function Drawer(props: DrawerProps): any {
  const { layout, auth } = props
  const classes = useStyles()

  if (!layout || !auth || !auth.user) {
    throw new Error('Invalid props')
  }

  return (
    <MaterialDrawer
      anchor={'left'}
      open={layout.drawer}
      onClose={layout.toggleDrawer}>
      <Grid container direction='column'>
        <List>
          <ListItem>
            <ListItemText>
              <Typography variant='h6'>{auth.user.name}</Typography>
              <Typography variant='button'>{auth.user.email}</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <Divider />
      </Grid>
      <Spacer />
      <List className={classes.list}>
        {menuItems.map(item => (
          <DrawerItem
            key={item.route}
            auth={auth}
            layout={layout}
            item={item}
          />
        ))}
      </List>
    </MaterialDrawer>
  )
}
export const EasyOfferDrawer = inject('layout', 'auth')(observer(Drawer))
