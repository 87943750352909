import { Button, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { route } from 'preact-router'
import { Header } from '../../components/header'
import AppRoutes from '../../routes'

export const ProductListHeader: FunctionComponent = () => {
  return (
    <Header
      title='Produtos'
      description='Adicione e edite os produtos do Oferta Fácil'>
      <Grid container justifyContent='flex-end' alignItems='flex-end'>
        <Button
          onClick={(): any => route(AppRoutes.ProductEdit.replace(':key?', ''))}
          variant='contained'
          color='primary'
          disableElevation>
          Criar Produto
        </Button>
      </Grid>
    </Header>
  )
}
