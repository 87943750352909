import { useEffect, useState } from 'preact/hooks'
import uploadImage from '../../image/upload'
import { DTypes, locator } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { ImageProvider } from '../providers/image'
import { Image } from '../entities/image'

export interface ImageEditState {
  busy: boolean
  onFieldChange: any
  onImageSelection: (file: File) => void
  onSave: any
  image: Image
}

export const useImageEditState = (key?: string): ImageEditState => {
  const [image, setImage] = useState<Image>({})
  const [busy, setBusy] = useState(false)

  const ImageService = locator.get<ImageProvider>(DTypes.ImageProvider)
  const store = locator.get<RootStore>(DTypes.Store)

  useEffect(() => {
    const fetchImage = async (): Promise<void> => {
      try {
        const image = await ImageService.image(key as string)
        setImage(image)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar a imagem', true)
      }
    }
    if (key) {
      fetchImage()
    }
  }, [])

  const onImageSelection = async (file: File): Promise<void> => {
    try {
      const imageURL = await uploadImage(file, 'image')
      setImage({ ...image, imageURL })
    } catch (e) {
      store.layout.showSnackbar('Erro ao enviar a imagem', true)
    }
  }

  const onSave = async (): Promise<void> => {
    if (!image.imageURL) {
      return store.layout.showSnackbar('Preencha todos os campos', true)
    }

    try {
      setBusy(true)

      await ImageService.update(image)
      store.layout.showSnackbar('Imagem salva com sucesso')

      if (!key) {
        setImage({})
      }
      setBusy(false)
    } catch (error) {
      setBusy(false)
      store.layout.showSnackbar('Erro ao salvar', true)
    }
  }

  const onFieldChange = (name: string) => (e: any): void => {
    setImage({
      ...image,
      [name]: e.target.value,
    })
  }

  return { image, onSave, busy, onFieldChange, onImageSelection }
}
