import {
  Chip,
  Divider,
  FilledInput,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { FunctionComponent, h } from 'preact'
import { useState } from 'preact/hooks'
import { Company } from '../../company/entities/company'
import { FlatButton, RaisedButton } from '../../components/button'
import { TypoDescription } from '../../components/typography'
import { User } from '../entities/user'

export interface UserItemProps {
  user: User
  handleChangeRemovalsAmount: (company: Company, amount: number) => void
  handleChangeUserStripeId: (userId: string, subscriptionId: string) => void
  handleActiveSubscription: (company: Company) => void
  handleRemoveSubscription: (company: Company) => void
}

export const UserItem: FunctionComponent<UserItemProps> = props => {
  const {
    user,
    handleActiveSubscription,
    handleRemoveSubscription,
    handleChangeRemovalsAmount,
    handleChangeUserStripeId,
  } = props

  const [stripeId, setStripeId] = useState<string>(user.stripeId)
  const [removals, setRemovals] = useState<number>(
    user.company?.removalUsage.removals as number,
  )

  return (
    <div>
      <Grid key={user._key}>
        <Grid
          style={{
            padding: 16,
            margin: 8,
          }}>
          <Grid direction='column' container>
            <Grid container item justifyContent='space-between'>
              <Typography variant='subtitle1'>{user.email}</Typography>
              {user?.company?.isTrial && (
                <Chip size='small' color='primary' label='Trial' />
              )}
              {user?.company?.subscription?.status && (
                <Chip
                  size='small'
                  style={{
                    color: 'white',
                    backgroundColor: 'green',
                  }}
                  label={user.company.subscription.status}
                />
              )}
            </Grid>

            <div>
              <Grid container style={{ marginTop: 16 }}>
                <Grid spacing={2}>
                  <TypoDescription
                    text={`Total de remoções: ${user.company?.removalUsage.totalRemovals}`}
                  />
                  <TypoDescription
                    text={`Refill será: ${DateTime.fromISO(
                      user.company?.removalUsage.refillAt as string,
                    ).toFormat('dd/MM/yyyy HH:mm')}`}
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 16 }}>
                <Grid spacing={2}>
                  <TextField
                    variant='filled'
                    label='Stripe ID'
                    onChange={(e: any) => setStripeId(e.target.value)}
                    value={stripeId}
                  />
                  <TextField
                    variant='filled'
                    label='Remoções'
                    style={{ marginLeft: 16 }}
                    type='number'
                    onChange={(e: any) => setRemovals(Number(e.target.value))}
                    value={removals}
                  />
                </Grid>
                <Grid style={{ marginLeft: 16 }} item>
                  <RaisedButton
                    onClick={(): any => {
                      handleChangeRemovalsAmount(
                        user?.company as Company,
                        removals,
                      )
                      handleChangeUserStripeId(user?._id as string, stripeId)
                    }}
                    label='Salvar'
                  />
                </Grid>
              </Grid>
            </div>

            <Grid container style={{ marginTop: 16 }}>
              <Grid item>
                <FlatButton
                  onClick={(): any =>
                    handleActiveSubscription(user?.company as Company)
                  }
                  label='Ativar Assinatura'
                />
              </Grid>
              <Grid style={{ marginLeft: 16 }} item>
                <FlatButton
                  onClick={(): any =>
                    handleRemoveSubscription(user?.company as Company)
                  }
                  label='Remover Assinatura'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    </div>
  )
}
