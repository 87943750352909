import { Box, makeStyles, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { PersonalData } from './personal'
import { SignatureData } from './subscription'
import { UserDetailsState } from './state'
import { TrialData } from './trial'
import { OffersData } from './offers'

const useStyles = makeStyles({})

export const UserDetails: FunctionComponent<UserDetailsState> = props => {
  const classes = useStyles()

  if (!props.user) return null

  return (
    <Grid container classes={classes}>
      <Box padding={3} />
      <Grid container>
        <PersonalData {...props} />
        <SignatureData {...props} />
        <OffersData {...props} />
      </Grid>
    </Grid>
  )
}
