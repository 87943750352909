export class Colors {
  static accentColor = '#2196F3'
  static lightAccentColor = '#E8F0FE'

  static white = '#fff'

  static textColor = '#202124'
  static textBodyColor = '#3C4043'
  static textDescriptionColor = '#5F6368'
  static textAccentColor = '#2196F3'
  static textCaptionColor = '#5F6368'

  static dividerColor = '#DADCE0'
  static textInputColor = '#F1F3F4'
}
