import { h, FunctionComponent } from 'preact'
import { Button, Typography } from '@material-ui/core'

interface ButtonProps {
  label: string
  onClick: () => void
  primary?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export const RaisedButton: FunctionComponent<ButtonProps> = (
  props: ButtonProps,
) => {
  const { label, onClick, primary, disabled, ...rest } = props
  return (
    <Button
      color={primary ? 'primary' : 'secondary'}
      disableElevation
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      <Typography color={primary ? 'inherit' : 'primary'} variant='button'>
        {label}
      </Typography>
    </Button>
  )
}

export function FlatButton(props: ButtonProps): any {
  const { label, onClick, primary, disabled } = props
  return (
    <Button
      color={primary ? 'primary' : 'inherit'}
      disableElevation
      disabled={disabled}
      onClick={onClick}>
      <Typography
        color={primary ? 'primary' : 'textSecondary'}
        variant='button'>
        {label}
      </Typography>
    </Button>
  )
}
