const AppRoutes = {
  Background: '/backgrounds',
  Business: '/business',
  CatchAll: '/:*?',
  EditBackground: '/backgrounds/edit/:key?',
  CreateBackground: '/backgrounds/create',
  EditBusiness: '/businesses/edit/:key?',
  EditTag: '/tags/edit/:key?',
  EditUser: '/users/edit/:key?',
  Home: '/',
  Login: '/login',
  Orders: '/pedidos',
  OrdersView: '/pedidos/:key',
  Product: '/products',
  ProductEdit: '/products/edit/:key?',
  Images: '/images',
  ImagesEdit: '/images/edit/:key?',
  Tag: '/tags',
  Users: '/users',
  UserDetails: '/user/:key',
  ProductReview: '/products-review',
  Offers: '/encartes',
  Reports: '/reports',
}

export default AppRoutes
