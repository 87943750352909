import { createMuiTheme } from '@material-ui/core/styles'
import { Colors } from './colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.accentColor,
      contrastText: Colors.white,
    },
    background: {
      default: Colors.white,
    },
    secondary: {
      main: Colors.lightAccentColor,
    },
    text: {
      disabled: Colors.dividerColor,
    },
    action: {
      disabled: Colors.dividerColor,
    },
  },
  typography: {
    h6: {
      fontFamily: 'GoogleSans',
      color: Colors.textColor,
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'GoogleSans',
      fontWeight: 700,
      color: Colors.textColor,
    },
    h4: {
      fontFamily: 'GoogleSans',
      fontWeight: 500,
      fontSize: '2rem',
      color: Colors.textColor,
    },
    body1: {
      fontSize: '1rem',
      color: Colors.textDescriptionColor,
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '.0142857143em',
      lineHeight: '1.25rem',
      color: Colors.textBodyColor,
    },
    caption: {
      fontSize: '0.8125rem',
      color: Colors.textCaptionColor,
      letterSpacing: '.025em',
    },
    button: {
      fontFamily: 'GoogleSans',
      fontSize: '0.875rem',
      color: Colors.textDescriptionColor,
      fontWeight: 500,
      textTransform: 'none',
    },
  },
})
