import { Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { BackButton } from '../../components/back'
import { RaisedButton } from '../../components/button'
import { Header } from '../../components/header'
import AppRoutes from '../../routes'
import { UserDetailsState } from './state'

export const UserDetailsHeader: FunctionComponent<UserDetailsState> = props => {
  const { handleSave } = props
  return (
    <Header
      title='Visualizar usuário'
      leading={<BackButton url={AppRoutes.Users} />}>
      <Grid container justifyContent='flex-end' alignItems='flex-end'>
        <RaisedButton primary onClick={handleSave} label='Salvar' />
      </Grid>
    </Header>
  )
}
