import { Button, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { BackButton } from '../../components/back'
import { Header } from '../../components/header'
import AppRoutes from '../../routes'

interface ProductEditHeaderProps {
  busy: boolean
  onSave: () => void
}

export const ProductEditHeader: FunctionComponent<ProductEditHeaderProps> = props => {
  const { onSave, busy } = props
  return (
    <Header
      title='Editar Produto'
      leading={<BackButton url={AppRoutes.Product} />}>
      <Grid container justifyContent='flex-end' alignItems='flex-end'>
        <Button
          disabled={busy}
          onClick={onSave}
          variant='contained'
          color='primary'
          disableElevation>
          Salvar Alterações
        </Button>
      </Grid>
    </Header>
  )
}
