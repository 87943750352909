import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'
import { h, FunctionComponent } from 'preact'

interface Props {
  label?: string
}
export const LinearLoadingIndicator: FunctionComponent<Props> = ({ label }) => {
  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Box padding={1}>
          <LinearProgress variant='indeterminate' />
          {label && (
            <Typography variant='caption' align='center'>
              {label}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
