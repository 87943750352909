export const searchCompanies = `
  query searchCompanies($email: String!) {
    searchCompanies(email: $email) {
      _id
      _key
      _rev
      email
      active
      isTrial
      trialStart
      trialEnd
      isExpired
      createdAt
      retrial
      numOffers
      lastOffer
      numProducts
      ratings {
          date
          rating
      }
      subscription {
          currentPeriodEnd
          currentPeriodStart
          id
          status
      }
    }
  }
`

export const subscribersByStatusQuery = `
 query subscribersByStatus {
  subscribersByStatus {
    status
    amount
  }
 }
`

export const trialsByMonthQuery = `
 query trialsByMonth {
  trialsByMonth {
    month
    year
    total
    sum
  }
 }
`

export const subscribersByDayQuery = `
 query subscribersByDay {
  subscribersByDay {
    doy
    day
    total
    sum
  }
 }
`

export const trialsByDayQuery = `
 query trialsByDay {
  trialsByDay {
    doy
    day
    total
    sum
  }
 }
`

export const subscribersByMonthQuery = `
 query subscribersByMonth {
  subscribersByMonth {
    month
    year
    total
    sum
    mrr
  }
 }
`

export const canceledByMonthQuery = `
 query canceledByMonth {
  canceledByMonth {
    month
    year
    total
    sum
    mrr
  }
 }
`
