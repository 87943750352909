import { injectable } from 'inversify'
import { AuthStore } from './auth'
import { LayoutStore } from './layout'

@injectable()
export class RootStore {
  layout: LayoutStore
  auth: AuthStore

  constructor() {
    this.auth = new AuthStore()
    this.layout = new LayoutStore()
  }
}

export const store = new RootStore()
