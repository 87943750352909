import {
  BarChartOutlined,
  MoneyOutlined,
  ShoppingCartOutlined,
} from '@material-ui/icons'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ImageIcon from '@material-ui/icons/Image'
import LabelIcon from '@material-ui/icons/Label'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import PersonIcon from '@material-ui/icons/Person'
import { h } from 'preact'
import AppRoutes from '../routes'

export interface MenuItem {
  label: string
  route: string
  permission?: string
  icon: any
  staff?: boolean
}

export const menuItems: MenuItem[] = [
  {
    label: 'Pedidos',
    route: AppRoutes.Orders,
    icon: <MoneyOutlined />,
  },
  {
    label: 'Products',
    route: AppRoutes.Product,
    icon: <DashboardIcon />,
  },
  {
    label: 'Artes',
    route: AppRoutes.Background,
    icon: <PermMediaIcon />,
  },
  {
    label: 'Images',
    route: AppRoutes.Images,
    icon: <ImageIcon />,
  },
  {
    label: 'Tags',
    route: AppRoutes.Tag,
    icon: <LabelIcon />,
  },
  {
    label: 'User',
    route: AppRoutes.Users,
    icon: <PersonIcon />,
  },
  {
    label: 'Relatórios',
    route: AppRoutes.Reports,
    icon: <BarChartOutlined />,
  },
  {
    label: 'Encartes',
    route: AppRoutes.Offers,
    icon: <ShoppingCartOutlined />,
  },
  {
    label: 'Products Review',
    route: AppRoutes.ProductReview,
    icon: <DashboardIcon />,
  },
]
