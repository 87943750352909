import { Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { ProductEditForm } from './form'
import { ProductEditHeader } from './header'
import { useProductEditState } from './state'

export const ProductEditScreen: FunctionComponent<any> = props => {
  const { matches } = props
  const state = useProductEditState(matches.key)
  return (
    <Container>
      <ProductEditHeader busy={state.busy} onSave={state.onSave} />
      <ProductEditForm {...state} />
    </Container>
  )
}
