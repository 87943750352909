export const fetchBackgroundsQuery = `
  query Backgrounds {
    backgrounds {
      _id
      _key
      _rev
      name
      imageURL
      format
      tags
    }
  }
`

export const fetchBackgroundQuery = `
  query Background ($id: String!) {
    background(id: $id) {
      _id
      _key
      _rev
      name
      imageURL
      format
      tags
    }
  }
`
