export const adminOffers = `
  query AdminOffers {
    adminOffers {
      _id
      _key
      _rev
      name
      company
      preview
      priceFontSize
      nameFontSize
      textColor
      showTags
      textFamily
      textStyle
      texts {
        id
        text
        color
        height
        width
        posX
        posY
        scaleX
        scaleY
        fontFamily
        fontSize
        attrs
        fontStyle
        textAlign
        index
      }
      background {
        _id
        _key
        _rev
        name
        imageURL
        format
      }
      format {
        code
        description
        size
        ratio
        height
        width
        vertical
      }
      tag {
        _id
        _key
        _rev
        name
        imageURL
      }
      logos {
        id
        _id
        _key
        _rev
        imageURL
        posX
        posY
        scaleX
        scaleY
        attrs
        index
      }
      products {
        _id
        _key
        _rev
        id
        name
        imageURL
        height
        price
        width
        posX
        posY
        scaleX
        scaleY
        tagVisible
        attrs
        index
        tagPos {
          x
          y
        }
        textPos {
          x
          y
        }
        nameAttrs
        tagAttrs
        priceAttrs
        namePos {
          x
          y
        }
        pricePos {
          x
          y
        }
      }
    }
  }
`
