import { Image } from '../entities/image'
import { useState, useEffect } from 'preact/hooks'
import { locator, DTypes } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { ImageProvider } from '../providers/image'

export interface ImageListState {
  images: Image[]
}

export const useImageListState = (): ImageListState => {
  const [images, setImages] = useState<Image[]>([])

  useEffect(() => {
    const store = locator.get<RootStore>(DTypes.Store)
    const imageService = locator.get<ImageProvider>(DTypes.ImageProvider)

    const fetchImages = async (): Promise<void> => {
      try {
        const images = await imageService.images()
        setImages(images)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar as imagens', true)
      }
    }
    fetchImages()
  }, [])

  return { images }
}
