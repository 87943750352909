import { injectable } from 'inversify'
import { action, observable } from 'mobx'
import { route } from 'preact-router'
import { Constants } from '../constants'
import { DTypes, locator } from '../infra/registry'
import { Storage } from '../infra/storage'
import AppRoutes from '../routes'
import { User } from '../users/entities/user'

@injectable()
export class AuthStore {
  @observable user: User | undefined
  @observable token: string | undefined

  @action
  setCredentials = (user: User, token: string): void => {
    const storage = locator.get<Storage>(DTypes.Storage)

    if (user) {
      this.user = user
      storage.setItem(Constants.User, JSON.stringify(user))
    }
    if (token) {
      this.token = token
      storage.setItem(Constants.Token, token)
    }
  }

  @action
  logout = (): void => {
    this.user = undefined
    this.token = undefined

    localStorage.removeItem(Constants.Token)
    localStorage.removeItem(Constants.User)

    route(AppRoutes.Login)
  }
}
