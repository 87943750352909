export const updateRemovalUsageMutation = `
    mutation updateRemovalUsage($company: String!, $amount: Int){
        updateRemovalUsage(company: $company, amount: $amount)
    }
`

export const updateCompanyMutation = `
  mutation UpdateCompany($data: CompanyInput!) {
    updateCompany(data: $data) {
      _id
      _key
      _rev
    }
  }
`
