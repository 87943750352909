import { Model } from '../../infra/model'

export const getStatusDisplayName = (status: string): string => {
  switch (status) {
    case 'pending':
      return 'Aguardando pagamento'
    case 'payed':
      return 'Pedido pago'
    case 'payment_error':
      return 'Erro de pagamento'
    case 'production':
      return 'Em produção'
    case 'change':
      return 'Em atualização'
    case 'completed':
      return 'Arte finalizada'
    default:
      return 'Status desconhecido'
  }
}

export type OrderStatus =
  | 'pending'
  | 'payed'
  | 'production'
  | 'change'
  | 'completed'
  | 'payment_error'

export interface OrderPayment {
  id: string
  status: string
  method: string
  methodId: string
  createdAt: string
  amount: number
}

export interface OrderHistory {
  code: string
  details: string
  createdAt: string
}

interface OrderFile {
  format: string
  imageURL: string
}

export interface Order extends Model {
  company: string
  status: OrderStatus
  statusDetails: string
  theme: string
  business: string
  details: string
  palette: string
  changes: number
  payment: OrderPayment
  files: OrderFile[]
  history: OrderHistory[]
}
