export const cancelSubscriptionMutation = `
  mutation CancelSubscription($sub: String!) {
    cancelSubscription(sub: $sub)
  }
`

export const cancelChargeMutation = `
  mutation CancelCharge($sub: String!, $charge: String!) {
    cancelCharge(sub: $sub, charge: $charge)
  }
`
