import { Container } from 'inversify'
import { BackgroundProvider } from '../backgrounds/providers/background'
import { CompanyProvider } from '../company/providers/company'
import { ImageProvider } from '../images/providers/image'
import { OfferProvider } from '../offers/providers/offer'
import { OrderProvider } from '../order/providers/order'
import { PaymentProvider } from '../payment/providers/payment'
import { ProductProvider } from '../products/providers/product'
import { RootStore, store } from '../stores/root'
import { TagProvider } from '../tags/providers/tag'
import { UserProvider } from '../users/providers/user'
import { ApiService } from './api'
import { DateProvider } from './date'
import { Storage } from './storage'

export const locator = new Container()

export const DTypes = {
  ApiService: Symbol.for('ApiService'),
  BackgroundProvider: Symbol.for('BackgroundProvider'),
  CompanyProvider: Symbol.for('CompanyProvider'),
  DateProvider: Symbol.for('DateProvider'),
  ImageProvider: Symbol.for('ImageProvider'),
  OrderProvider: Symbol.for('OrderProvider'),
  PaymentProvider: Symbol.for('PaymentProvider'),
  ProductProvider: Symbol.for('ProductProvider'),
  Storage: Symbol.for('StorageService'),
  Store: Symbol.for('Store'),
  TagProvider: Symbol.for('TagProvider'),
  UserProvider: Symbol.for('UserProvider'),
  OfferProvider: Symbol.for('OfferProvider'),
}

export function registerServices(): void {
  locator
    .bind<ApiService>(DTypes.ApiService)
    .to(ApiService)
    .inSingletonScope()
  locator.bind<Storage>(DTypes.Storage).toConstantValue(localStorage)
  locator.bind<RootStore>(DTypes.Store).toConstantValue(store)
  locator
    .bind<UserProvider>(DTypes.UserProvider)
    .to(UserProvider)
    .inSingletonScope()
  locator
    .bind<CompanyProvider>(DTypes.CompanyProvider)
    .to(CompanyProvider)
    .inSingletonScope()
  locator
    .bind<ProductProvider>(DTypes.ProductProvider)
    .to(ProductProvider)
    .inSingletonScope()
  locator
    .bind<ImageProvider>(DTypes.ImageProvider)
    .to(ImageProvider)
    .inSingletonScope()
  locator
    .bind<BackgroundProvider>(DTypes.BackgroundProvider)
    .to(BackgroundProvider)
    .inSingletonScope()
  locator
    .bind<PaymentProvider>(DTypes.PaymentProvider)
    .to(PaymentProvider)
    .inSingletonScope()
  locator
    .bind<TagProvider>(DTypes.TagProvider)
    .to(TagProvider)
    .inSingletonScope()
  locator
    .bind<DateProvider>(DTypes.DateProvider)
    .to(DateProvider)
    .inSingletonScope()
  locator
    .bind<OfferProvider>(DTypes.OfferProvider)
    .to(OfferProvider)
    .inSingletonScope()
  locator
    .bind<OrderProvider>(DTypes.OrderProvider)
    .to(OrderProvider)
    .inSingletonScope()
}
