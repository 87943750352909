import { route } from 'preact-router'
import { useState } from 'preact/hooks'
import { ApiService } from '../infra/api'
import { DTypes, locator } from '../infra/registry'
import AppRoutes from '../routes'
import { RootStore } from '../stores/root'
import { AuthenticateUserUseCase } from './cases/authenticate'

export interface LoginScreenState {
  email: string
  password: string
  error: string
  setEmail: Function
  setPassword: Function
  handleAuthenticate: any
  path?: any
}

export const useLoginState = (): LoginScreenState => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')

  const handleAuthenticate = async (): Promise<void> => {
    const store = locator.get<RootStore>(DTypes.Store)
    const apiService = locator.get<ApiService>(DTypes.ApiService)

    const authenticateUserUseCase = new AuthenticateUserUseCase(
      store,
      apiService,
    )

    try {
      await authenticateUserUseCase.execute(email, password)
      route(AppRoutes.Home)
    } catch (e) {
      setError(e.message)
    }
  }

  return { email, setEmail, password, setPassword, error, handleAuthenticate }
}
