import { Button, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { route } from 'preact-router'
import { Header } from '../../components/header'
import AppRoutes from '../../routes'

export const ImageListHeader: FunctionComponent = () => {
  return (
    <Header
      title='Imagens'
      description='Adicione e edite as imagens do Oferta Fácil'>
      <Grid container justifyContent='flex-end' alignItems='flex-end'>
        <Button
          onClick={(): any => route(AppRoutes.ImagesEdit.replace(':key?', ''))}
          variant='contained'
          color='primary'
          disableElevation>
          Criar Imagem
        </Button>
      </Grid>
    </Header>
  )
}
