import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { DTypes, locator } from '../../infra/registry'
import { OrderFilters } from '../entities/filter'
import { Order } from '../entities/order'
import { updateOrderMutation } from '../graphql/mutations'
import { adminOrdersQuery, orderQuery } from '../graphql/queries'

@injectable()
export class OrderProvider {
  private apiService: ApiService

  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  update = (data: Order): Promise<Order[]> => {
    return this.apiService
      .graphql<{ updateOrder: Order[] }>(updateOrderMutation, { data })
      .then(r => r.updateOrder)
  }

  adminOrders = (filter: OrderFilters): Promise<Order[]> => {
    return this.apiService
      .graphql<{ adminOrders: Order[] }>(adminOrdersQuery, {
        filter,
      })
      .then(r => r.adminOrders)
  }

  order = (key: string): Promise<Order> => {
    return this.apiService
      .graphql<{ order: Order }>(orderQuery, { id: `orders/${key}` })
      .then(r => r.order)
  }
}
