import { Grid } from '@material-ui/core'
import { h, VNode, FunctionComponent } from 'preact'
import Dropzone from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export interface ImageDropZoneProps {
  onImageSelection: (file: File) => void
}

export const ImageDropZone: FunctionComponent<ImageDropZoneProps> = ({
  onImageSelection,
}: any) => {
  const handleImageSelection = (files: File[]): void => {
    if (!files || files.length === 0) {
      return
    }
    onImageSelection(files[0])
  }

  return (
    <Dropzone onDrop={handleImageSelection}>
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }): VNode => {
        const style = {
          ...baseStyle,
          ...(isDragActive ? activeStyle : {}),
          ...(isDragAccept ? acceptStyle : {}),
          ...(isDragReject ? rejectStyle : {}),
        }
        return (
          <Grid {...getRootProps({ style: style as any })}>
            <input {...(getInputProps() as any)} />
            <p>Arraste a imagem AQUI ou clique para selecionar um arquivo</p>
          </Grid>
        )
      }}
    </Dropzone>
  )
}
