import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Bar, BarChart, Tooltip, CartesianGrid, XAxis } from 'recharts'
import {
  DayAggregate,
  MonthAggregate,
  StatusAggregate,
} from '../company/entities/stats'
import { CompanyProvider } from '../company/providers/company'
import { DTypes, locator } from '../infra/registry'
import { store } from '../stores/root'
import { getStatusDisplayName } from './status'

export const AccountStats: FunctionComponent = () => {
  const [subByStatus, setSubByStatus] = useState<StatusAggregate[]>([])
  const [subByMonth, setSubByMonth] = useState<MonthAggregate[]>([])
  const [subByDay, setSubByDay] = useState<DayAggregate[]>([])
  const [trialsByDay, setTrialsByDay] = useState<DayAggregate[]>([])
  const [trialsByMonth, setTrialsByMonth] = useState<MonthAggregate[]>([])
  const [canceledByMonth, setCanceledByMonth] = useState<MonthAggregate[]>([])

  const companyProvider = locator.get<CompanyProvider>(DTypes.CompanyProvider)

  useEffect(() => {
    const fetchAccountStats = async (): Promise<void> => {
      try {
        const [
          subByStatus,
          subByMonth,
          subByDay,
          trialsByMonth,
          canceledByMonth,
          trialsByDay,
        ] = await Promise.all([
          companyProvider.subscribersByStatus(),
          companyProvider.subscribersByMonth(),
          companyProvider.subscribersByDay(),
          companyProvider.trialsByMonth(),
          companyProvider.canceledByMonth(),
          companyProvider.trialsByDay(),
        ])
        setSubByStatus(subByStatus)
        setSubByMonth(subByMonth)
        setSubByDay(subByDay)
        setTrialsByMonth(trialsByMonth)
        setCanceledByMonth(canceledByMonth)
        setTrialsByDay(trialsByDay)
      } catch (e) {
        store.layout.showSnackbar(e.message, true)
      }
    }

    fetchAccountStats()
  }, [])

  return (
    <Grid container direction='column'>
      <Typography variant='h6'>Assinantes por Status</Typography>
      <Box padding={1} />
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {subByStatus.map(status => {
                return (
                  <TableRow key={status.status}>
                    <TableCell>{getStatusDisplayName(status.status)}</TableCell>
                    <TableCell>{status.amount}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>
                  {subByStatus.reduce((a, b) => a + b.amount, 0)}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Grid>
      <Box padding={3} />
      <Typography variant='h6'>Assinantes/mês</Typography>
      <Box padding={1} />
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mês/Ano</TableCell>
                  <TableCell>Trials</TableCell>
                  <TableCell>Assinantes</TableCell>
                  <TableCell>Acumulado</TableCell>
                  <TableCell>Conversão</TableCell>
                  <TableCell>Cancelados</TableCell>
                  <TableCell>Retenção</TableCell>
                  <TableCell>MRR Líquido</TableCell>
                  <TableCell>MRR Perdido</TableCell>
                  <TableCell>MRR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subByMonth.map(status => {
                  const canceled = canceledByMonth.find(
                    c => c?.month === status?.month && c?.year === status?.year,
                  )
                  const canceledAccounts = canceled?.total || 0
                  const churn = (
                    (1 - canceledAccounts / status.sum) *
                    100
                  ).toFixed(1)
                  const trial = trialsByMonth.find(
                    c => c?.month === status?.month && c?.year === status?.year,
                  )
                  const trialAccounts = trial?.total || 0
                  const trialConversion = (
                    (status.total / trialAccounts) *
                    100
                  ).toFixed(1)
                  const lostMrr = (canceledAccounts * 99.9).toFixed(0)
                  const netMrr = (status.total * 99.9).toFixed(0)
                  return (
                    <TableRow key={status.month + status.year}>
                      <TableCell>{`${status.month}/${status.year}`}</TableCell>
                      <TableCell>{trialAccounts}</TableCell>
                      <TableCell>{status.total}</TableCell>
                      <TableCell>{status.sum}</TableCell>
                      <TableCell>{`${trialConversion}%`}</TableCell>
                      <TableCell>{canceledAccounts}</TableCell>
                      <TableCell>{`${churn}%`}</TableCell>
                      <TableCell>{`R$ ${netMrr}`}</TableCell>
                      <TableCell>{`R$ ${lostMrr}`}</TableCell>
                      <TableCell>{`R$ ${status.mrr}`}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box padding={2} />
      <Typography variant='h6'>Trials/dia</Typography>
      <Box padding={1} />
      <Grid item>
        <BarChart width={1200} height={400} data={trialsByDay}>
          <Bar dataKey='total' fill='#82ca9d' />
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='day' />
          <Tooltip />
        </BarChart>
      </Grid>
      <Box padding={2} />
      <Typography variant='h6'>Assinantes/dia</Typography>
      <Box padding={1} />
      <Grid item>
        <BarChart width={1200} height={400} data={subByDay}>
          <Bar dataKey='total' fill='#82ca9d' />
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='day' />
          <Tooltip />
        </BarChart>
      </Grid>
    </Grid>
  )
}
