import { GridList, GridListTile, Link, makeStyles } from '@material-ui/core'
import { FunctionComponent, h, VNode } from 'preact'
import { route } from 'preact-router'
import AppRoutes from '../../routes'
import { getImageUrl } from '../../utils/url'
import { TagListState } from './state'

const useStyles = makeStyles({
  image: {
    objectFit: 'scale-down',
  },
})

export const TagList: FunctionComponent<TagListState> = props => {
  const { tags } = props
  const classes = useStyles()
  return (
    <GridList cellHeight={100} cols={6}>
      {tags &&
        tags.map(
          (s): VNode => (
            <GridListTile key={s._id}>
              <Link
                onClick={(): boolean =>
                  route(AppRoutes.EditTag.replace(':key?', s._key as string))
                }>
                <img
                  className={classes.image}
                  src={getImageUrl(s.imageURL || '', 180)}
                />
              </Link>
            </GridListTile>
          ),
        )}
    </GridList>
  )
}
