import {
  Box,
  Chip,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FlatButton, RaisedButton } from '../../components/button'
import { ImageDropZone } from '../../components/dropzone'
import { Header } from '../../components/header'
import uploadImage from '../../image/upload'
import { DTypes, locator } from '../../infra/registry'
import { store } from '../../stores/root'
import { Background } from '../entities/background'
import { BackgroundProvider } from '../providers/background'

export const BackgroundEditScreen: FunctionComponent<any> = props => {
  const { matches } = props
  const key = matches.key

  const [busy, setBusy] = useState<boolean>(false)
  const [file, setFile] = useState<File | any>(null)
  const [background, setBackground] = useState<Background>({})
  const [tags, setTags] = useState<string[]>([])
  const [tag, setTag] = useState<string>('')

  const backgroundProvider = locator.get<BackgroundProvider>(
    DTypes.BackgroundProvider,
  )

  useEffect(() => {
    const fetchCreatable = async (): Promise<void> => {
      try {
        setBusy(true)
        const background = await backgroundProvider.background(
          `backgrounds/${key}`,
        )
        if (background) {
          const response = await fetch(background.imageURL || '')
          const file = await response.blob()
          setFile(file)

          const reader = new FileReader()
          reader.onload = (): void => {
            setBackground(background)
            setTags(background.tags?.split(',') || [])
          }
          reader.readAsDataURL(file)
        }
      } catch (e) {
        store.layout.showSnackbar(e.message, true)
      } finally {
        setBusy(false)
      }
    }

    if (key) {
      fetchCreatable()
    }
  }, [])

  const handleTagDelete = (tag: string): void => {
    tags.splice(tags.indexOf(tag), 1)
    setTags([...tags])
  }

  const handleUpdateImage = async (): Promise<void> => {
    try {
      const idx = background?.imageURL?.lastIndexOf('/') || 0
      const fileName = background?.imageURL?.substring(idx + 1)
      await uploadImage(file as File, 'background', fileName)
      store.layout.showSnackbar('Imagem atualizada com sucesso')
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    }
  }

  const handleFileSelection = async (file: File): Promise<void> => {
    try {
      setBusy(true)
      const imageURL = await uploadImage(file, 'background')
      background.imageURL = imageURL
    } catch (e) {
      store.layout.showSnackbar('Erro ao enviar imagem', true)
    } finally {
      setBusy(false)
    }
  }

  const handleSave = async (): Promise<void> => {
    if (tags.length === 0) {
      return store.layout.showSnackbar('Digite as tags', true)
    }

    if (!background.imageURL) {
      return store.layout.showSnackbar('Selecione um fundo', true)
    }

    try {
      setBusy(true)
      background.tags = tags.join(',')
      await backgroundProvider.update(background)
    } catch (e) {
      store.layout.showSnackbar(e.message)
    } finally {
      setBusy(false)
    }
  }

  if (!background) {
    return <LinearProgress />
  }

  return (
    <Container maxWidth='md'>
      <Header title='Editar Arte' description='Envie e edite suas artes'>
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          {busy ? (
            <LinearProgress />
          ) : (
            <RaisedButton
              primary
              label='Salvar Alterações'
              onClick={handleSave}
            />
          )}
        </Grid>
      </Header>
      <Box padding={1} />
      <Grid container>
        {!busy && (
          <Grid container spacing={3}>
            <Grid container spacing={2} direction='column' item xs={12}>
              <Grid container item>
                {tags.length > 0 &&
                  tags.map(t => (
                    <Box key={t} padding={1}>
                      <Chip label={t} onDelete={handleTagDelete} />
                    </Box>
                  ))}
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  placeholder='Digite uma tag e pressione ENTER'
                  value={tag}
                  onChange={(e): void => setTag(e.target.value)}
                  onKeyPress={(e): void => {
                    if (e.key === 'Enter') {
                      if (tags.includes(tag)) return
                      tags.push(tag)
                      setTag('')
                    }
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid item>
              {!background.imageURL ? (
                <ImageDropZone onImageSelection={handleFileSelection} />
              ) : (
                <Grid
                  container
                  direction='column'
                  justifyContent='space-between'>
                  <Typography variant='h6' align='center'>
                    {background.format?.toUpperCase()}
                  </Typography>
                  <img src={background.imageURL} style={{ width: '320px' }} />
                  <Grid container justifyContent='space-between'>
                    <FlatButton
                      primary
                      label='Remover'
                      onClick={(): void => {
                        const bg = Object.assign({}, background)
                        delete bg.imageURL
                        setBackground(bg)
                      }}
                    />
                    <FlatButton
                      primary
                      label='Atualizar'
                      onClick={handleUpdateImage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
