import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { DTypes, locator } from '../../infra/registry'
import { Offer } from '../entities/offer'
import { adminOffers } from '../graphql/queries'

@injectable()
export class OfferProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  offers = async (): Promise<Offer[]> => {
    return this.apiService
      .graphql<{ adminOffers: Offer[] }>(adminOffers)
      .then(r => r.adminOffers)
  }
}
