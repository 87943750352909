import useSWR from 'swr'
import { DTypes, locator } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { Background } from '../entities/background'
import { BackgroundProvider } from '../providers/background'

export interface BackgroundListState {
  backgrounds: Background[] | undefined
  onSave: (background: Background) => void
}

export const useBackgroundListState = (): BackgroundListState => {
  const backgroundService = locator.get<BackgroundProvider>(
    DTypes.BackgroundProvider,
  )
  const store = locator.get<RootStore>(DTypes.Store)

  const { data: backgrounds } = useSWR(
    '/backgrounds',
    backgroundService.backgrounds,
  )

  const onSave = async (background: Background): Promise<void> => {
    try {
      await backgroundService.update(background)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    }
  }

  return { backgrounds, onSave }
}
