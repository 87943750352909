import { Box, Grid, Typography } from '@material-ui/core'
import { h } from 'preact'

interface FormFieldProps {
  label: string
  children: any
}

export function FormField(props: FormFieldProps): any {
  const { label, children } = props
  return (
    <Grid container>
      <Grid item xs={4} md={3}>
        <Box paddingTop={4}>
          <Typography variant='body2' style={{ color: 'black' }}>
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        direction='column'
        justifyContent='center'
        item
        xs={12}
        md={9}>
        {children}
      </Grid>
    </Grid>
  )
}
