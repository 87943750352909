import { render } from 'preact'
import 'reflect-metadata'

import { registerServices } from './infra/registry'
registerServices()

import { App } from './app.tsx'

let app
if (typeof document === 'undefined') {
  app = App
} else {
  render(<App />, document.getElementById('root'))
}

export default app
