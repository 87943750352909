import { useEffect, useState } from 'preact/hooks'
import uploadImage from '../../image/upload'
import { DTypes, locator } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { Product } from '../entities/product'
import { ProductProvider } from '../providers/product'

export interface ProductEditState {
  busy: boolean
  onFieldChange: any
  onImageSelection: (file: File) => void
  onSave: any
  product: Product
}

export const useProductEditState = (key?: string): ProductEditState => {
  const [product, setProduct] = useState<Product>({})
  const [busy, setBusy] = useState(false)

  const productService = locator.get<ProductProvider>(DTypes.ProductProvider)
  const store = locator.get<RootStore>(DTypes.Store)

  useEffect(() => {
    const fetchProduct = async (): Promise<void> => {
      try {
        const product = await productService.product(key as string)
        setProduct(product)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar o produto', true)
      }
    }
    if (key) {
      fetchProduct()
    }
  }, [])

  const onImageSelection = async (file: File): Promise<void> => {
    try {
      const imageURL = await uploadImage(file, 'product')
      setProduct({ ...product, imageURL })
    } catch (e) {
      store.layout.showSnackbar('Erro ao enviar a imagem', true)
    }
  }

  const onSave = async (): Promise<void> => {
    if (!product.name || !product.imageURL) {
      return store.layout.showSnackbar('Preencha todos os campos', true)
    }

    try {
      setBusy(true)

      await productService.update(product)
      store.layout.showSnackbar('Produto salvo com sucesso')

      if (!key) {
        setProduct({})
      }
      setBusy(false)
    } catch (error) {
      setBusy(false)
      store.layout.showSnackbar('Erro ao salvar', true)
    }
  }

  const onFieldChange = (name: string) => (e: any): void => {
    setProduct({
      ...product,
      [name]: e.target.value,
    })
  }

  return { product, onSave, busy, onFieldChange, onImageSelection }
}
