import { Grid } from '@material-ui/core'
import { FunctionComponent, h, VNode } from 'preact'
import { BackgroundItem } from './item'
import { BackgroundListState } from './state'

export const BackgroundList: FunctionComponent<BackgroundListState> = props => {
  const { backgrounds, onSave } = props
  return (
    <Grid container spacing={1}>
      {backgrounds &&
        backgrounds.map(
          (bg): VNode => (
            <BackgroundItem key={bg._key} onSave={onSave} background={bg} />
          ),
        )}
    </Grid>
  )
}
