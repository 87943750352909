import { Box, Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { TagListHeader } from './header'
import { TagList } from './list'
import { useTagListState } from './state'

export const TagListScreen: FunctionComponent<{}> = () => {
  const state = useTagListState()
  return (
    <Container>
      <TagListHeader />
      <Box padding={3} />
      <TagList {...state} />
    </Container>
  )
}
