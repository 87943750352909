import { Fragment, FunctionComponent, h } from 'preact'
import Router, { route } from 'preact-router'
import { useEffect } from 'preact/hooks'
import { BackgroundCreateScreen } from './backgrounds/create/screen'
import { BackgroundEditScreen } from './backgrounds/edit/screen'
import { BackgroundListScreen } from './backgrounds/list/screen'
import { EasyOfferAppBar } from './components/appbar'
import { SnackbarController } from './components/snackbar'
import { ImageEditScreen } from './images/edit/screen'
import { ImageListScreen } from './images/list/screen'
import { EasyOfferDrawer } from './menu/drawer'
import { OfferList } from './offers/list'
import { OrderListScreen } from './order/list/screen'
import { OrderViewScreen } from './order/view/screen'
import { ProductEditScreen } from './products/edit/screen'
import { ProductListScreen } from './products/list/screen'
import { ProductReviewScreen } from './products/review/screen'
import { ReportScreen } from './reports/screen'
import AppRoutes from './routes'
import { TagEditScreen } from './tags/edit/screen'
import { TagListScreen } from './tags/list/screen'
import { UserDetailsScreen } from './users/details/screen'
import { UserListScreen } from './users/list/screen'

export const Home: FunctionComponent<any> = props => {
  const { url } = props

  useEffect((): void => {
    if (url === '/') {
      route(AppRoutes.Reports)
    }
  }, [])

  return (
    <Fragment>
      <EasyOfferDrawer />
      <EasyOfferAppBar />
      <Router path={AppRoutes.CatchAll}>
        <OfferList path={AppRoutes.Offers} />
        <ProductListScreen path={AppRoutes.Product} />
        <ProductEditScreen path={AppRoutes.ProductEdit} />
        <BackgroundListScreen path={AppRoutes.Background} />
        <BackgroundCreateScreen path={AppRoutes.CreateBackground} />
        <BackgroundEditScreen path={AppRoutes.EditBackground} />
        <TagListScreen path={AppRoutes.Tag} />
        <TagEditScreen path={AppRoutes.EditTag} />
        <UserListScreen path={AppRoutes.Users} />
        <UserDetailsScreen path={AppRoutes.UserDetails} />
        <ImageListScreen path={AppRoutes.Images} />
        <ImageEditScreen path={AppRoutes.ImagesEdit} />
        <ProductReviewScreen path={AppRoutes.ProductReview} />
        <ReportScreen path={AppRoutes.Reports} />
        <OrderListScreen path={AppRoutes.Orders} />
        <OrderViewScreen path={AppRoutes.OrdersView} />
        {/* <ProductEditScreen path={AppRoutes.EditProduct} />
        <BackgroundScreen path={AppRoutes.Background} />
        <BackgroundEditScreen path={AppRoutes.EditBackground} />
        <TagScreen path={AppRoutes.Tag} />
        <TagEditScreen path={AppRoutes.EditTag} />
        <UserScreen path={AppRoutes.Users} />
        <UserEditScreen path={AppRoutes.EditUser} />
        <BusinessScreen path={AppRoutes.Business} />
        <BusinessEditScreen path={AppRoutes.EditBusiness} /> */}
      </Router>
      <SnackbarController />
    </Fragment>
  )
}
