import { Box, Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Header } from '../../components/header'
import { DTypes, locator } from '../../infra/registry'
import { store } from '../../stores/root'
import { Order } from '../entities/order'
import { OrderProvider } from '../providers/order'
import { OrderList } from './list'

export const OrderListScreen: FunctionComponent = () => {
  const [orders, setOrders] = useState<Order[] | null>(null)
  const orderProvider = locator.get<OrderProvider>(DTypes.OrderProvider)

  useEffect(() => {
    const fetchOrders = async (): Promise<void> => {
      try {
        const orders = await orderProvider.adminOrders({
          limit: 0,
          offset: 0,
        })
        setOrders(orders)
      } catch (e) {
        store.layout.showSnackbar(e.message, true)
      }
    }

    fetchOrders()
  }, [])

  if (!orders) {
    return <Box padding={3}></Box>
  }

  return (
    <Container>
      <Header title='Pedidos' />
      <Box padding={2} />
      <OrderList orders={orders} />
    </Container>
  )
}
