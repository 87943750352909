import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { Model } from '../../infra/model'
import { DTypes, locator } from '../../infra/registry'
import { Product } from '../entities/product'
import {
  reviewProductMutation,
  updateProductMutation,
} from '../graphql/mutations'
import {
  fetchProductQuery,
  fetchProductsQuery,
  FetchProductsReviewQuery,
  similarProductsQuery,
} from '../graphql/queries'

@injectable()
export class ProductProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  products = async (): Promise<Product[]> => {
    return this.apiService
      .graphql<{ products: Product[] }>(fetchProductsQuery)
      .then(r => r.products)
  }

  product = async (id: string): Promise<Product> => {
    return this.apiService
      .graphql<{ product: Product }>(fetchProductQuery, { id })
      .then(r => r.product)
  }

  productsToReview = async (): Promise<Product> => {
    return this.apiService
      .graphql<{ productsToReview: Product }>(FetchProductsReviewQuery)
      .then(r => r.productsToReview)
  }

  update = async (data: Product): Promise<Model> => {
    return this.apiService
      .graphql<{ updateProduct: Model }>(updateProductMutation, { data })
      .then(r => r.updateProduct)
  }

  similar = async (name: string): Promise<Product[]> => {
    return this.apiService
      .graphql<{ similarProducts: Product[] }>(similarProductsQuery, { name })
      .then(r => r.similarProducts)
  }

  review = async (product: Product, status: string): Promise<Model> => {
    return this.apiService
      .graphql<{ reviewProduct: Model }>(reviewProductMutation, {
        product,
        status,
      })
      .then(r => r.reviewProduct)
  }
}
