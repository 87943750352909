import { StateUpdater, useEffect, useState } from 'preact/hooks'
import { CompanyProvider } from '../../company/providers/company'
import { DateProvider } from '../../infra/date'
import { DTypes, locator } from '../../infra/registry'
import { RootStore } from '../../stores/root'
import { User } from '../entities/user'
import { UserProvider } from '../providers/user'

export interface UserDetailsState {
  user: User | undefined
  setUser: StateUpdater<User>
  handleSave: () => void
  formatDateTime: (isoDate: string) => string
  formatTimestamp: (timestamp: number) => string
}

export const useUserDetailState = (key?: string): UserDetailsState => {
  const [user, setUser] = useState<User>({} as any)

  const userProvider = locator.get<UserProvider>(DTypes.UserProvider)
  const date = locator.get<DateProvider>(DTypes.DateProvider)
  const store = locator.get<RootStore>(DTypes.Store)

  const formatDateTime = (isoDate: string): string => {
    if (!isoDate) return 'Data inválida'
    return date.fromISOToDisplay(isoDate, true)
  }

  const formatTimestamp = (timestamp: number): string => {
    if (!timestamp) return 'Data inválida'
    return date.fromTimestampToDisplay(timestamp, true)
  }

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      try {
        const user = await userProvider.user(key as string)
        setUser(user)
      } catch (e) {
        store.layout.showSnackbar('Erro ao carregar o usuário', true)
      }
    }
    if (key) {
      fetchUser()
    }
  }, [])

  const handleSave = async (): Promise<void> => {
    const companyProvider = locator.get<CompanyProvider>(DTypes.CompanyProvider)
    const store = locator.get<RootStore>(DTypes.Store)

    const companyUpdate = {
      _id: user.company?._id,
      _key: user.company?._key,
      _rev: user.company?._rev,
      subscription: user.company?.subscription,
      trialEnd: user.company?.trialEnd,
      isTrial: user.company?.isTrial,
    }

    try {
      await companyProvider.update(companyUpdate)
      store.layout.showSnackbar('Usuário salvo com sucesso')
    } catch (e) {
      console.log(e)
    }
  }

  return { user, setUser, handleSave, formatDateTime, formatTimestamp }
}
