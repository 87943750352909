import { RootStore } from '../../stores/root'
import { ApiService } from '../../infra/api'

export class AuthenticateUserUseCase {
  constructor(private store: RootStore, private apiService: ApiService) {}

  async execute(email: string, password: string): Promise<void> {
    const credentials = await this.apiService.authenticate(email, password)

    if (credentials.token) {
      this.store.auth.setCredentials(credentials.user, credentials.token)
    }
  }
}
