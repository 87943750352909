import { Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { UserDetails } from './details'
import { UserDetailsHeader } from './header'
import { useUserDetailState } from './state'

export const UserDetailsScreen: FunctionComponent<{
  matches?: any
}> = props => {
  const { matches } = props
  const state = useUserDetailState(matches.key)
  return (
    <Container>
      <UserDetailsHeader {...state} />
      <UserDetails {...state} />
    </Container>
  )
}
