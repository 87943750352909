export const updateUserMutation = `
    mutation UpdateUser($data: UserInput!){
        updateUser(data: $data){
            _id
            _key
            _rev
        }
    }
`

export const activateSubscriptionMutation = `
    mutation activateSubscription($id: String!){
        activateSubscription(id: $id)
    }
`

export const updateStripeIdMutation = `
    mutation UpdateStripeId($id: String!, $stripeId: String!){
        updateStripeId(id: $id, stripeId: $stripeId){
            _id
            _key
            _rev
        }
    }
`
