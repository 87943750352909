export const subscriptionQuery = `
  query Subscription($id: String!) {
    subscription(id: $id) {
      id
      paymentMethod
      status
      nextCharge
    }
  }
`

export const chargesQuery = `
  query Charges($sub: String!) {
    charges(sub: $sub) {
      id
      amount
      status
      paymentMethod
      createdAt
      expiresAt
      history {
        message
        createdAt
      }
    }
  }
`
