import { IconButton, makeStyles } from '@material-ui/core'
import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined'
import { h } from 'preact'
import { route } from 'preact-router'

const useStyles = makeStyles({
  root: {
    margin: '0 16px 0 0',
  },
})

export function BackButton({ url }: { url: string }): any {
  const classes = useStyles()
  return (
    <IconButton className={classes.root} onClick={(): any => route(url)}>
      <ArrowBackOutlined />
    </IconButton>
  )
}
