import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Fragment, FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FlatButton } from '../../components/button'
import { ImageDropZone } from '../../components/dropzone'
import { Header } from '../../components/header'
import { LinearLoadingIndicator } from '../../components/loading'
import uploadImage from '../../image/upload'
import { DTypes, locator } from '../../infra/registry'
import { store } from '../../stores/root'
import { getStatusDisplayName, Order, OrderStatus } from '../entities/order'
import { OrderProvider } from '../providers/order'

const formats = ['stories', 'feed', 'square']

export const OrderViewScreen: FunctionComponent<any> = props => {
  const { matches } = props
  const key: string = matches.key

  const [busy, setBusy] = useState(false)
  const [files, setFiles] = useState<any>({})
  const [order, setOrder] = useState<Order | null>(null)
  const orderProvider = locator.get<OrderProvider>(DTypes.OrderProvider)

  useEffect(() => {
    const fetchOrder = async (): Promise<void> => {
      try {
        const order = await orderProvider.order(key)

        const files: any = {}
        if (order.files) {
          for (const file of order.files) {
            files[file.format] = file.imageURL
          }
        }

        setFiles(files)
        setOrder(order)
      } catch (e) {
        store.layout.showSnackbar(e.message, true)
      }
    }

    fetchOrder()
  }, [])

  const handleStatusChange = async (status: OrderStatus): Promise<void> => {
    if (!order) return

    if (status === 'completed') {
      order.files = []
      for (const format of formats) {
        if (!files[format]) {
          return store.layout.showSnackbar('Adicione todos os arquivos', true)
        }
        order.files.push({
          format,
          imageURL: files[format],
        })
      }
    }

    try {
      setBusy(true)
      order.status = status

      await orderProvider.update(order)
    } catch (e) {
      store.layout.showSnackbar(e.message, true)
    } finally {
      setBusy(false)
    }
  }

  if (!order) {
    return (
      <Box padding={3}>
        <LinearLoadingIndicator />
      </Box>
    )
  }

  const handleFileSelection = (name: string) => async (
    file: File,
  ): Promise<void> => {
    const imageURL = await uploadImage(file, 'background')
    setFiles({ ...files, [name]: imageURL })
  }

  return (
    <Container>
      <Header
        title={`Pedido #${order._key} - ${getStatusDisplayName(order.status)}`}>
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          {busy ? (
            <Box padding={1}>
              <LinearLoadingIndicator />
            </Box>
          ) : (
            <Fragment>
              {order.status === 'payed' && (
                <Button
                  onClick={(): Promise<void> =>
                    handleStatusChange('production')
                  }
                  variant='contained'
                  color='primary'
                  disableElevation>
                  Iniciar Produção
                </Button>
              )}
              {order.status === 'production' && (
                <Button
                  onClick={(): Promise<void> => handleStatusChange('completed')}
                  variant='contained'
                  color='primary'
                  disableElevation>
                  Finalizar Arte
                </Button>
              )}
            </Fragment>
          )}
        </Grid>
      </Header>
      <Box padding={2} />
      <Grid container justifyContent='space-around'>
        <Grid item>
          <Typography variant='h6'>Cores</Typography>
          <img src={`/assets/colors/${order.palette}.png`} width='80' />
        </Grid>
        <Grid item>
          <Typography variant='h6'>Ramo de atuação</Typography>
          <Typography variant='body1'>{order.business}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6'>Tema</Typography>
          <Typography variant='body1'>{order.theme}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6'>Detalhes</Typography>
          <Typography variant='body1'>{order.details}</Typography>
        </Grid>
      </Grid>
      <Box padding={2} />
      <Divider />
      <Box padding={2} />
      <Grid container spacing={3}>
        {(order.status === 'production' || order.status === 'completed') &&
          formats.map(f => (
            <Grid key={f} item xs={4}>
              {files[f] ? (
                <Fragment>
                  <img src={files[f]} width={320} />
                  <FlatButton
                    onClick={(): void => {
                      delete files[f]
                    }}
                    label='Remover'
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant='h6'>{f.toUpperCase()}</Typography>
                  <Box padding={1} />
                  <ImageDropZone onImageSelection={handleFileSelection(f)} />
                </Fragment>
              )}
            </Grid>
          ))}
      </Grid>
    </Container>
  )
}
