export const fetchTagsQuery = `
  query Tags {
    tags{
      _id
      _key
      _rev
      name
      imageURL
      tags
    }
  }
`

export const fetchTagQuery = `
  query Tag($id: String!) {
    tag(id: $id) {
      _id
      _key
      _rev
      name
      imageURL
      tags
    }
  }
`
