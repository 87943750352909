import { Box, Container, Grid, TextField } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { ImageDropZone } from '../../components/dropzone'
import { FormField } from '../../components/form_field'
import { getImageUrl } from '../../utils/url'
import { TagEditState } from './state'

export const TagEditForm: FunctionComponent<TagEditState> = props => {
  const { tag, onFieldChange, onImageSelection } = props
  return (
    <Container maxWidth='md'>
      <Grid container direction='column'>
        <Box padding={3} />
        <FormField label='Nome *'>
          <TextField
            onChange={onFieldChange('name')}
            placeholder='Nome do produto'
            fullWidth
            variant='outlined'
            value={tag.name}
          />
        </FormField>
        <Box padding={1} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ImageDropZone onImageSelection={onImageSelection} />
          </Grid>
          <Grid item xs={6}>
            <Box textAlign='center'>
              {tag.imageURL && (
                <img src={getImageUrl(tag.imageURL, 180)} width={180} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
