import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { h } from 'preact'
import { Spacer } from '../components/spacer'
import { LoginScreenState } from './state'

const useStyles = makeStyles({
  container: {
    height: '100vh',
  },
  root: {
    padding: 24,
    maxWidth: 360,
    margin: '0 auto',
    marginTop: 64,
  },
})

export const LoginForm = (props: LoginScreenState): any => {
  const classes = useStyles()

  const {
    email,
    error,
    setEmail,
    password,
    setPassword,
    handleAuthenticate,
  } = props

  return (
    <Container className={classes.container} maxWidth='lg'>
      <Grid className={classes.root} container direction='column'>
        <Grid item xs={12}>
          <Typography align='center' variant='h6'>
            Oferta Fácil
          </Typography>
          <Spacer />
          <Typography align='center' variant='body1'>
            Crie posts de oferta com qualidade profissional
          </Typography>
        </Grid>
        <Spacer double />
        <Grid item xs={12}>
          <form>
            <TextField
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant='outlined'
              label='Email'
              fullWidth
            />
            <Spacer />
            <TextField
              value={password}
              onChange={e => setPassword(e.target.value)}
              variant='outlined'
              label='Senha'
              type='password'
              fullWidth
            />
            <Spacer double />
            <Typography align='center' variant='button' color='error'>
              {error}
            </Typography>
            {error && <Spacer double />}
            <Button
              onClick={handleAuthenticate}
              disableElevation
              variant='contained'
              color='primary'
              fullWidth>
              <Typography variant='button' color='inherit'>
                Acessar o Oferta Fácil
              </Typography>
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}
