export const fetchUsersQuery = `
 query Users {
     users {
         _id
         _key
         _rev
         stripeId
         company {
          active
          isTrial
          trialStart
          trialEnd
          isExpired
          createdAt
          numUsersCreated
          removalUsage {
              refillAt
              removals
              totalRemovals
          }
      }
     }
 }
`

export const usersByEmailQuery = `
query UsersByEmail($email: String!) {
    usersByEmail(email: $email) {
        _id
        _key
        _rev
        name
        email
        stripeId
        company {
            _id
            _key
            _rev
            active
            isTrial
            trialStart
            trialEnd
            isExpired
            createdAt
            retrial
            numOffers
            lastOffer
            numProducts
            ratings {
                date
                rating
            }
            subscription {
                currentPeriodEnd
                currentPeriodStart
                id
                status
            }
            removalUsage {
                refillAt
                removals
                totalRemovals
            }
        }
    }
}
`

export const fetchUserQuery = `
 query User($id: String!) {
     user(id: $id){
         _id
         _key
         _rev
         name
         email
         staff
         stripeId
         company {
            _id
            _key
            _rev
             active
             isTrial
             isExpired
             trialStart
             numOffers
             lastOffer
             numProducts
             trialEnd
             createdAt
             retrial
             ratings {
              date
              rating
            }
             subscription {
                currentPeriodEnd
                currentPeriodStart
                id
                status
                trialEnd
                trialStart
            }
            removalUsage {
                refillAt
                removals
                totalRemovals
            }
         }
     }
 }
 `

export const fetchAdminStatsQuery = `
    query AdminStatsUsers($date: Int) {
      adminStatsUsers(date: $date) {
          numUsersCreated
          numUsersActive
          numUsersCanceled
 }
}
 `

export const subscribedUsersQuery = `
    query SubscribedUsers {
        subscribedUsers {
            _id
            _key
            _rev
            name
            email
            company {
                _id
                _key
                _rev
                active
                isTrial
                trialStart
                trialEnd
                isExpired
                createdAt
                retrial
                numOffers
                lastOffer
                numProducts
                gateway
                ratings {
                    date
                    rating
                }
                subscription {
                    currentPeriodEnd
                    currentPeriodStart
                    id
                    status
                }
            }
        }
    }
 `
