export const updateProductMutation = `
  mutation UpdateTag($data: ProductInput!) {
    updateProduct(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const reviewProductMutation = `
  mutation reviewProduct($product: ProductInput!, $status: String!) {
    reviewProduct(product: $product, status: $status) {
      _id
    }
  }
`
