export const adminOrdersQuery = `
  query AdminOrders($filter: FilterOrder!) {
    adminOrders(filter: $filter) {
      _id
      _key
      status
      theme
      palette
      createdAt
      updatedAt
    }
  }
`

export const orderQuery = `
  query Order($id: String!) {
    order(id: $id) {
      _id
      _key
      _rev
      status
      style
      theme
      details
      palette
      business
      createdAt
      updatedAt
      files {
        format
        imageURL
      }
      payment {
        id
        status
        method
        methodId
        createdAt
        amount
      }
    }
  }
`
