import { Box, Container } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { ProductListHeader } from './header'
import { ProductList } from './list'
import { useProductListState } from './state'

export const ProductListScreen: FunctionComponent = () => {
  const state = useProductListState()
  return (
    <Container>
      <ProductListHeader />
      <Box padding={3} />
      <ProductList {...state} />
    </Container>
  )
}
