import { injectable } from 'inversify'
import { ApiService } from '../../infra/api'
import { Model } from '../../infra/model'
import { DTypes, locator } from '../../infra/registry'
import { Tag } from '../entities/tag'
import { updateTagMutation } from '../graphql/mutations'
import { fetchTagQuery, fetchTagsQuery } from '../graphql/queries'

@injectable()
export class TagProvider {
  private apiService: ApiService
  constructor() {
    this.apiService = locator.get<ApiService>(DTypes.ApiService)
  }

  tags = async (): Promise<Tag[]> => {
    return this.apiService
      .graphql<{ tags: Tag[] }>(fetchTagsQuery)
      .then(r => r.tags)
  }

  tag = async (id: string): Promise<Tag> => {
    return this.apiService
      .graphql<{ tag: Tag }>(fetchTagQuery, { id })
      .then(r => r.tag)
  }

  update = async (data: Tag): Promise<Model> => {
    return this.apiService
      .graphql<{ updateTag: Model }>(updateTagMutation, { data })
      .then(r => r.updateTag)
  }
}
