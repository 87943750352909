import { Grid } from '@material-ui/core'
import { Fragment, FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FlatButton } from '../components/button'
import { DTypes, locator } from '../infra/registry'
import { store } from '../stores/root'
import { Canvas } from './preview'
import { OfferProvider } from './providers/offer'

export const OfferList: FunctionComponent = () => {
  const [offers, setOffers] = useState<any[]>([])
  const [active, setActive] = useState<number>(0)
  const offerProvider = locator.get<OfferProvider>(DTypes.OfferProvider)

  const drawOffer = (offer: any): void => {
    if (!offer) return
    const { width = 1080, height = 1080 } = offer.format
    const konva = new Canvas(offer, {
      color: '#ffffff',
      container: 'art-canvas',
      width,
      height,
    })

    konva.init()
  }

  useEffect(() => {
    const fetchOffers = async (): Promise<void> => {
      try {
        const offers = await offerProvider.offers()
        setOffers(offers)
        drawOffer(offers[0])
      } catch (e) {
        console.log(e)
      }
    }
    if (active === 0) {
      fetchOffers()
    } else {
      drawOffer(offers[active])
    }
  }, [active])

  const previous = (): void => {
    if (active === 0) {
      return store.layout.showSnackbar('Esse é o primeiro encarte', true)
    }
    setActive(active - 1)
  }

  const next = (): void => {
    if (active === offers.length - 1) {
      return store.layout.showSnackbar('Esse é o último encarte', true)
    }
    setActive(active + 1)
  }

  return (
    <Fragment>
      <Grid
        style={{
          width: 200,
          position: 'fixed',
          right: 32,
          bottom: 32,
        }}
        container>
        <Grid item xs={6}>
          <FlatButton primary label='Anterior' onClick={previous} />
        </Grid>
        <Grid item xs={6}>
          <FlatButton primary label='Próxima' onClick={next} />
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'auto',
          overflowX: 'auto',
          height: 'calc(100vh - 64px)',
          backgroundColor: '#ededed',
        }}>
        <div id='art-canvas' />
      </div>
    </Fragment>
  )
}
