import { injectable } from 'inversify'
import { action, observable } from 'mobx'

@injectable()
export class LayoutStore {
  @observable drawer = false
  @observable snackbar = {
    open: false,
    message: '',
    alert: false,
  }

  @action
  toggleDrawer = (): void => {
    this.drawer = !this.drawer
  }

  @action
  showSnackbar = (message: string, alert?: boolean): void => {
    this.snackbar = {
      open: true,
      message,
      alert: alert || false,
    }
  }

  @action
  hideSnackbar = (): void => {
    this.snackbar = {
      open: false,
      message: '',
      alert: false,
    }
  }
}
