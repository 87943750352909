/* tslint:disable */
/* eslint-disable */

import Konva from 'konva'
import { getImageUrl } from '../utils/url'
import uuidv4 from '../utils/uuid'

export class Canvas extends Konva.Stage {
  _hasCustomScale = false
  _bgLayer: Konva.Layer
  _defaultLayer: Konva.Layer
  _textTransformer: Konva.Transformer
  _defaultTransformer: Konva.Transformer
  _selected: any[] = []
  _offer: any = {}

  constructor(offer: any, config: any) {
    super(config)
    this._offer = offer

    this._bgLayer = new Konva.Layer()
    this._defaultLayer = new Konva.Layer()

    this.add(this._bgLayer)
    this.add(this._defaultLayer)

    this._textTransformer = new Konva.Transformer({
      id: uuidv4(),
      name: 'text-transformer',
      keepRatio: true,
      padding: 4,
      anchorSize: 12,
      borderStroke: 'black',
      borderStrokeWidth: 3,
      borderDash: [3, 3],
      anchorStroke: 'black',
      anchorFill: 'white',
      visible: true,
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        return newBox.width < 50 || newBox.width > this.width()
          ? oldBox
          : newBox
      },
    })
    this._defaultTransformer = new Konva.Transformer({
      id: uuidv4(),
      name: 'transformer',
      keepRatio: true,
      padding: 4,
      centeredScaling: true,
      anchorSize: 12,
      borderStroke: 'black',
      borderStrokeWidth: 3,
      borderDash: [3, 3],
      anchorStroke: 'black',
      anchorFill: 'white',
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      boundBoxFunc: (oldBox, newBox) => {
        return newBox.width < 50 ||
          newBox.height < 50 ||
          newBox.height > this.height() ||
          newBox.width > this.width()
          ? oldBox
          : newBox
      },
    })

    this._defaultLayer.add(this._defaultTransformer)
    this._defaultLayer.add(this._textTransformer)

    this.on('click tap', e => {
      if (e.target.name() === 'background') {
        this.clearSelected()
        this.draw()
      }
    })

    const container = this.container()
    container.tabIndex = 1
    container.focus()
    container.addEventListener('keydown', e => {
      if (e.keyCode === 46) {
        if (this._selected && this._selected.length > 0) {
          this.clearSelected()
        }
      }
    })
  }

  init() {
    this.fitCanvasToScreen()
    this.drawOffer()
  }

  drawOffer() {
    this.drawBackground()
    this.drawProducts()
    this.drawElements()
    this.drawTexts()
  }

  selectProduct = (id: string) => {}

  selectAllProducts = () => {}

  selectText = (id: any) => {
    const el = this.findOne((el: any) => el.id() === id)
    if (el) {
      this.selectTextNode(el)
    }
  }

  moveNodeUp = (id: any) => {
    const el = this.findOne((el: any) => el.id() === id)
    if (el) {
      el.moveUp()
      this.draw()
    }
  }

  moveNodeDown = (id: string) => {
    const el = this.findOne((el: any) => el.id() === id)
    if (el) {
      el.moveDown()
      this.draw()
    }
  }

  selectNode = (node: any, append: any) => {
    this._selected = [node]
    const existingNodes = this._defaultTransformer.getNodes()
    this._defaultTransformer.setNodes(
      append ? [...existingNodes, node] : this._selected,
    )
    this._defaultTransformer.moveToTop()
    this._textTransformer.setNodes([])
    this.draw()
  }

  selectTextNode = (node: any) => {
    this._selected = [node]
    this._textTransformer.setNodes(this._selected)
    this._textTransformer.moveToTop()
    this._defaultTransformer.setNodes([])
    this.draw()
  }

  clearSelected = () => {
    this._defaultTransformer.setNodes([])
    this._textTransformer.setNodes([])
    this.draw()
  }

  drawTexts() {
    const { texts } = this._offer
    if (!texts) return

    for (const text of texts) {
      this.drawText(text)
    }

    this._defaultLayer.draw()
  }

  drawText(text: any) {
    if (!text.id) {
      text.id = uuidv4()
    }

    let existingText = this.findOne((el: any) => el.id() === text.id)
    let konvaText = existingText || new Konva.Text()

    if (existingText) {
      konvaText.off('click tap')
      konvaText.off('transformend dragend')
    }

    konvaText.setAttrs({
      id: text.id,
      text: text.text,
      name: 'text',
      x: 100,
      y: 200,
      width: this.width() / 2,
      fontFamily: text.fontFamily || 'Roboto',
      fontStyle: text.fontStyle || 'normal',
      align: text.textAlign || 'center',
      fill: text.color || '#222222',
      fontSize: text.fontSize || 50,
      draggable: true,
    })

    if (text.attrs) {
      const rawAttrs =
        typeof text.attrs === 'string' ? JSON.parse(text.attrs) : text.attrs
      konvaText.setAttrs({
        x: rawAttrs.x,
        y: rawAttrs.y,
        width: rawAttrs.width,
      })
    }

    konvaText.on('click tap', e => {
      this.selectTextNode(e.target)
      this.draw()
    })

    konvaText.on('transformend dragend', e => {
      this.draw()
    })

    konvaText.on('transform', () => {
      konvaText.setAttrs({
        width: Math.max(konvaText.width() * konvaText.scaleX(), 80),
        scaleX: 1,
        scaleY: 1,
      })
      this.draw()
    })

    if (!existingText) {
      this._defaultLayer.add(konvaText as any)
      this.selectTextNode(konvaText)
    }
  }

  drawElements() {
    const { logos } = this._offer
    if (!logos) return

    const logoIds = logos.map((l: any) => l.id)
    const els: any = this.find((el: any) => el.name() === 'element')
    for (const el of els) {
      if (!logoIds.includes(el.id())) {
        el.destroy()
      }
    }

    for (const element of logos) {
      this.drawElement(element)
    }

    this.draw()
  }

  drawElement = async (element: any) => {
    if (!element.id) {
      element.id = uuidv4()
    }

    let existingElement = this.findOne((el: any) => el.id() === element.id)
    let konvaElement = existingElement || new Konva.Image()

    if (existingElement) {
      konvaElement.off('click tap')
      konvaElement.off('transformend dragend')
    }

    const elementImg: any = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(element.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    konvaElement.setAttrs({
      id: element.id || uuidv4(),
      name: 'element',
      x: 0,
      y: 0,
      image: elementImg,
      width: elementImg.width,
      height: elementImg.height,
      draggable: true,
    })

    konvaElement.on('click tap', e => {
      this.selectNode(e.target, e.evt.shiftKey)
      this.draw()
    })

    konvaElement.on('transformend dragend', e => {})

    this._defaultLayer.add(konvaElement as any)

    if (element.attrs) {
      const attrs = JSON.parse(element.attrs)
      delete attrs.image
      konvaElement.setAttrs(attrs)
    }

    if (element.index) {
      konvaElement.setZIndex(element.index)
    }

    this.draw()
  }

  removeElementFromCanvas = (id: string) => {
    let el = this.findOne((el: any) => el.id() === id)
    el && el.destroy()
  }

  drawProducts() {
    const { products } = this._offer
    if (!products) return

    const productIds = products.map((p: any) => p.id)
    const els: any[] = this.find((el: any) => el.name() === 'product') as any
    for (const el of els) {
      if (!productIds.includes(el.id())) {
        el.destroyChildren()
        el.destroy()
      }
    }

    for (const product of products) {
      this.drawProduct(product)
    }

    this._defaultLayer.draw()
    this.draw()
  }

  async drawProduct(product: any) {
    if (!product.id) {
      product.id = uuidv4()
    }

    let existingProductGroup: Konva.Group = this.findOne(
      (el: any) => el.id() === product.id,
    )
    let productGroup: Konva.Group = existingProductGroup || new Konva.Group()

    if (existingProductGroup) {
      productGroup.off('click tap')
      productGroup.off('transformend dragend')
    } else {
      this._defaultLayer.add(productGroup)
    }

    productGroup.setAttrs({
      id: product.id,
      name: 'product',
      draggable: true,
    })

    const toggleShowTags =
      this._offer.showTags !== false && product.tagVisible !== false

    const productImg: HTMLImageElement = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(product.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    const imageKey = `img-${product.id}`
    let existingImage = productGroup.getChildren(
      el => el.id() === imageKey,
    )[0] as Konva.Image
    let productKonvaImg: Konva.Image = existingImage || new Konva.Image()

    productKonvaImg.setAttrs({
      id: imageKey,
      x: 450,
      y: 450,
      name: 'product-element',
      image: productImg,
      offsetX: productImg.width / 2,
      offsetY: productImg.height / 2,
      width: productImg.width,
      height: productImg.height,
    })

    !existingImage && productGroup.add(productKonvaImg)

    const tagImg: HTMLImageElement = await new Promise(resolve => {
      const i = new Image()
      i.onload = () => resolve(i)
      i.src = getImageUrl(this._offer.tag.imageURL)
      i.crossOrigin = 'Anonymous'
    })

    const tagKey = `tag-${product.id}`
    let existingTag: Konva.Image = productGroup.getChildren(
      el => el.id() === tagKey,
    )[0] as Konva.Image
    let tagKonvaImg = existingTag || new Konva.Image()

    tagKonvaImg.setAttrs({
      id: tagKey,
      name: 'product-element',
      image: tagImg,
      offsetX: tagImg.width / 2,
      offsetY: tagImg.height / 2,
      width: tagImg.width,
      height: tagImg.height,
      visible: toggleShowTags,
    })

    !existingTag && productGroup.add(tagKonvaImg)

    tagKonvaImg.setAttrs({
      x: productKonvaImg.x() + tagKonvaImg.width() / 2,
      y: productKonvaImg.y() + tagKonvaImg.height() / 2,
    })

    if (product.tagAttrs) {
      const attrs = JSON.parse(product.tagAttrs)
      if (attrs.x && attrs.y) {
        attrs.x = productKonvaImg.x() - attrs.x
        attrs.y = productKonvaImg.y() - attrs.y
      }
      tagKonvaImg.setAttrs(attrs)
    }

    const priceKey = `price-${product.id}`
    let existingPrice = productGroup.getChildren(el => el.id() === priceKey)[0]
    let konvaPrice = existingPrice || new Konva.Text()

    konvaPrice.listening(false)

    konvaPrice.setAttrs({
      id: priceKey,
      name: 'product-element',
      text: product.price || '',
      fontSize: 80,
      fontWeight: 900,
      fontStyle: 'bold',
      fontFamily: 'Montserrat',
      absolutePositioned: true,
      textAlign: 'center',
      fill: '#ffffff',
      visible: toggleShowTags,
    })

    konvaPrice.setAttrs({
      offsetX: konvaPrice.width() / 2,
      offsetY: konvaPrice.height() / 2,
      x: tagKonvaImg.x(),
      y: tagKonvaImg.y(),
    })

    if (product.priceAttrs) {
      const attrs = JSON.parse(product.priceAttrs)
      konvaPrice.setAttrs(attrs)
    }

    !existingPrice && productGroup.add(konvaPrice as any)

    const nameKey = `name-${product.id}`
    let existingName = productGroup.getChildren(el => el.id() === nameKey)[0]
    let konvaName = existingName || new Konva.Text()

    const centerNameOnProduct =
      productKonvaImg.x() + productKonvaImg.width() / 4

    konvaName.setAttrs({
      id: nameKey,
      name: 'product-element',
      text: product.name,
      fontSize: 70,
      fontFamily: this._offer?.textFamily || 'Montserrat',
      fontStyle: this._offer?.textStyle || 'normal',
      fill: this._offer.textColor || '#000',
      align: 'center',
      x: centerNameOnProduct,
    })

    !existingName && productGroup.add(konvaName as any)

    if (
      konvaName.width() > productKonvaImg.width() + tagKonvaImg.width() / 2 &&
      !product.nameAttrs
    ) {
      konvaName.setAttrs({
        width: productKonvaImg.width() + tagKonvaImg.width() / 2,
      })
      const centerNameOnProduct =
        productKonvaImg.x() + productKonvaImg.width() / 4

      konvaName.setAttrs({
        x: centerNameOnProduct,
        y:
          productKonvaImg.y() -
          productKonvaImg.height() / 2 -
          konvaName.height(),
      })
    }

    konvaName.setAttrs({
      offsetX: konvaName.width() / 2,
      offsetY: konvaName.height() / 2,
    })

    if (!product.nameAttrs) {
      const centerNameOnProduct =
        productKonvaImg.x() + productKonvaImg.width() / 4

      konvaName.setAttrs({
        x: centerNameOnProduct,
        y:
          productKonvaImg.y() -
          productKonvaImg.height() / 2 -
          konvaName.height(),
      })
    }

    if (product.nameAttrs) {
      const attrs = JSON.parse(product.nameAttrs)
      attrs.x = productKonvaImg.x() - (attrs.x || 0)
      attrs.y = productKonvaImg.y() - (attrs.y || 0)
      konvaName.setAttrs(attrs)
    }

    if (product.attrs) {
      const attrs = JSON.parse(product.attrs)
      delete attrs.id
      productGroup.setAttrs(attrs)
    } else {
      productGroup.setAttr('scale', { x: 0.4, y: 0.4 })
    }

    productGroup.on('click tap', e => {
      if (e.target instanceof Konva.Group) {
        this.selectNode(e.target, e.evt.shiftKey)
      } else {
        this.selectNode(e.target.parent, e.evt.shiftKey)
      }
    })

    productGroup.on('transformend dragend', e => {})

    this._defaultLayer.draw()
  }

  fitCanvasToScreen() {
    const { width = 1080, height = 1080 } = this._offer.format

    const windowWidth =
      window.innerWidth - 500 > 1080 ? 1080 : window.innerWidth - 510

    const availableHeight = (window.innerHeight - 64) / height
    const availableWidth = windowWidth / width

    this.updateScale(
      availableWidth < availableHeight ? availableWidth : availableHeight,
    )
  }

  fitCanvasToWidth = () => {
    const windowWidth =
      window.innerWidth - 510 > 1080 ? 1080 : window.innerWidth - 510
    const widthScale = windowWidth / 1080
    this.updateScale(widthScale)
  }

  updateScale(scale: number) {
    const { width = 1080, height = 1080 } = this._offer.format

    this.scale({ x: scale, y: scale })
    this.setAttr('size', {
      top: 64,
      width: width * scale,
      height: height * scale,
    })

    this.drawScene()
  }

  drawBackground() {
    const { background } = this._offer
    if (!background) return

    const imageObj = new Image()
    imageObj.onload = () => {
      const konvaImg = new Konva.Image({
        id: background.id || uuidv4(),
        name: 'background',
        x: 0,
        y: 0,
        image: imageObj,
        width: imageObj.width,
        height: imageObj.height,
        draggable: false,
      })

      this._bgLayer.add(konvaImg)
      this._bgLayer.draw()
    }

    imageObj.src = getImageUrl(background.imageURL || '')
    imageObj.crossOrigin = 'Anonymous'
  }
}
