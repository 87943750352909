import { Box, Container, Typography, makeStyles, Grid } from '@material-ui/core'
import { FunctionComponent, h } from 'preact'
import { User } from '../entities/user'

const useStyles = makeStyles({})

interface PersonalDataProps {
  user: User | undefined
  formatDateTime: (isoDate: string) => string
}

export const PersonalData: FunctionComponent<PersonalDataProps> = props => {
  const { user, formatDateTime } = props

  if (!user) return null

  return (
    <Grid item xs={4}>
      <Typography variant='h5'>Dados Pessoais</Typography>
      <Box padding={3} />
      <Typography variant='h6'> Nome </Typography>
      <Typography variant='body2'>{user.name || 'Not registered'}</Typography>
      <Box padding={2} />
      <Typography variant='h6'> E-mail</Typography>
      <Typography variant='body2'>{user.email || ''}</Typography>
      <Box padding={2} />
      <Typography variant='h6'> Cadastrado em</Typography>
      <Typography variant='body2'>
        {formatDateTime(user.company?.createdAt || '')}
      </Typography>
    </Grid>
  )
}
