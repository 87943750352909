export const fetchImagesQuery = `
  query Images {
    images {
      _id
      _key
      _rev
      imageURL
    }
  }
`

export const fetchImageQuery = `
  query Image($id: String!) {
    image(id: $id) {
      _id
      _key
      _rev
      imageURL
    }
  }
`
