import { h, FunctionComponent } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { CloseOutlined, SearchOutlined } from '@material-ui/icons'
import {
  IconButton,
  InputAdornment,
  FilledInput,
  makeStyles,
} from '@material-ui/core'
import { useDebounce } from '../utils/debounce'
import { Colors } from '../view/colors'

const useStyles = makeStyles({
  root: {
    borderRadius: '8px !important',
  },
  input: {
    padding: 16,
    fontFamily: 'GoogleSans',
  },
})

interface SearchFieldProps {
  placeholder?: string
  onSearch: (term: string) => void
  onClear: () => void
}

export const SearchField: FunctionComponent<SearchFieldProps> = props => {
  const { placeholder, onSearch, onClear } = props
  const classes = useStyles()

  const [term, setTerm] = useState('')
  const debouncedTerm = useDebounce<string>(term, 300)

  useEffect(() => {
    if (term.length === 0) {
      onClear()
    }

    if (!term || term.length < 3) {
      return
    }

    const search = (): void => {
      if (debouncedTerm) {
        onSearch(term)
      }
    }
    search()
  }, [debouncedTerm])

  return (
    <FilledInput
      classes={{
        root: classes.root,
        input: classes.input,
      }}
      style={{
        borderRadius: '8px !important',
        backgroundColor: Colors.textInputColor,
      }}
      value={term}
      disableUnderline
      placeholder={placeholder}
      onChange={(e): void => setTerm(e.target.value)}
      fullWidth
      startAdornment={
        <InputAdornment position='end'>
          {term ? (
            <IconButton
              onClick={(): void => {
                setTerm('')
                onClear()
              }}>
              <CloseOutlined />
            </IconButton>
          ) : (
            <IconButton
              onClick={(): void => {
                setTerm('')
                onClear()
              }}>
              <SearchOutlined />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  )
}
